import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { handleOpenModal } from "../store/slices";

function Footer() {
  const location = useLocation();
  const dispatch = useDispatch()
  if (location.pathname === "/termandcondition") {
    return null;
  }
  return (
    <footer className="footer mt-3">
      <ul>
        <li>
          <button className="footer-link" onClick={()=> dispatch(handleOpenModal("termCondi"))}>
            Terms and Conditions & Privacy Policy
          </button>
        </li>
        <li className="link-seperator">|</li>
        <li>
          <span className="footer-link">© {(new Date().getFullYear())} Big Box Souq Portal LLC</span>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
