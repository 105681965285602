import mainImg from "../assets/our_partnership.png";
import HeartiLogo from "../assets/hearti_logo.png";
import HeartiImg from "../assets/hearti_img.jpg";
import HawaianLogo from "../assets/hawaian_logo.png";
import HawaianImg from "../assets/hawaian_img.jpg";
import KindLogo from "../assets/kind_lyf_logo.png";
import KindImg from "../assets/kind_lyf_img.jpg";
import JujuLogo from "../assets/juju_scoop_logo.png";
import JujuImg from "../assets/juju_scoop_img.jpg";

export default function PartnershipPage() {
  return (
    <div className="main container">
      <header className="aboutusHeader flex-row-reverse align-items-center justify-content-between mb-lg-5 pb-5 row">
        <div className="col-lg-6 col-md-12 mt-lg-0 mt-md-5">
          <h1 className="about-title">
            Our<div>Partnerships</div>
          </h1>
          <div className="">
            <div className="aboutus-info">
              We’ve partnered with local small businesses that have a healthy, mindful, mindset, just like us! We are
proud to have them on board and to offer their unique and incredible products to our customers.
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-12 ms-lg-2 mt-3 mt-lg-0">
          <img src={mainImg} alt="Our Partnership" className="w-100" />
        </div>
      </header>
      <div className="align-items-center justify-content-between my-lg-5 py-5 row">
        <div className="col-lg-6 col-md-12">
          <h1 className="about-title">
            <img src={HeartiLogo} alt="Hearti Grub" /><div>Hearti Grub</div>
          </h1>
          <div className="">
            <div className="aboutus-info">
              Heartigrub products are made with clean, superfood ingredients to help you feel and be your best self!
              Made with love and integrity, their products have no refined sugars, high in protein and natural fats, but
              most importantly taste delicious &amp; &#39;hearti&#39;.
              <br /><br />
              They come in Dairy free, Gluten-free, Keto and Vegan options too!
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-12 me-lg-2 mt-lg-0 mt-md-5">
          <img src={HeartiImg} alt="Hearti Grub" className="w-100" />
        </div>
      </div>
      <div className="align-items-center flex-row-reverse justify-content-between my-lg-5 py-5 row">
        
        <div className="col-lg-6 col-md-12 mt-lg-0 mt-md-5">
          <h1 className="about-title">
            <img src={HawaianLogo} alt="Hayawiiacom" /><div>Hayawiiacom</div>
          </h1>
          <div className="">
            <div className="aboutus-info">
              Hayawiiacom offer UAE homegrown food brands. They sell eco-conscious food with a zero-waste policy.
                Buy their pulses &amp; pastas, snacks, seasoning &amp; spices, flours, honey, spreads &amp; sauces from us
                today!Gluten Free, High Fibre, High Protein, Low Carb, Organic and Vegan options available.
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-12 ms-lg-2 mt-3 mt-lg-0">
          <img src={HawaianImg} alt="Hayawiiacom" className="w-100" />
        </div>
      </div>

      <div className="align-items-center justify-content-between my-lg-5 py-5 row">
        <div className="col-lg-6 col-md-12">
          <h1 className="about-title">
            <img src={KindLogo} alt="Kind Lyfe" /><div>Kind Lyfe</div>
          </h1>
          <div className="">
            <div className="aboutus-info">
              Kindlyfe was started by three siblings in their home! Their delicious homemade recipes are made for
individuals who may struggle with some intolerances. These gut friendly, delicious snacks don&#39;t
compromise on taste or texture, try them on our App today!
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-12 me-lg-2 mt-lg-0 mt-md-5">
          <img src={KindImg} alt="Kind Lyfe" className="w-100" />
        </div>
      </div>
      <div className="align-items-center flex-row-reverse justify-content-between my-lg-5 py-5 row">
        
        <div className="col-lg-6 col-md-12 mt-lg-0 mt-md-5">
          <h1 className="about-title">
            <img src={JujuLogo} alt="JuJu Scoops" /><div>JuJu Scoops</div>
          </h1>
          <div className="">
            <div className="aboutus-info">
              juju.scoops JuJu Scoops Ice Creams are an exotic blend of fruits, spices and herbs, handcrafted with
natural ingredients. Freshly made in the UAE. They offer a premium range of ice creams, including
unique vegan (plant-based) flavours!Natural Ingredients, Fruits and Exotic Spices, Premium and Vegan
Options.<br />Vegan, Gluten free, refined sugar free, Preservative free, and Non-GMO.
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-12 ms-lg-2 mt-3 mt-lg-0">
          <img src={JujuImg} alt="JuJu Scoops" className="w-100" />
        </div>
      </div>
      <div className="my-lg-5 py-5">
        <div className="">
          <h1 className="partner-title">
            Partner With Us
          </h1>
          <div className="partner-detail">
              We love supporting and partnering with local small businesses. If you would like to partner with
us and fit the following few requirements, then drop us an email at <a href="mailto:zainab@bigboxsouq.com">zainab@bigboxsouq.com</a>
<ul>
    <li>A company registered in the UAE</li>
<li>Have a passion for healthy food or alternative options</li>
<li>Have an Eco-Friendly Mindset</li>
</ul>
Working with us will mean access to our customer base with the same mindset, and simple, easy
delivery to all of Dubai!
          </div>
        </div>
      </div>
    </div>
  );
}
