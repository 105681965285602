import React, { useState } from "react";
import Signup from "./Signup";
import Success from "./Success";

function Modal({ showModal, setShowModal, refCode, setRefCode }) {
  const [register, setRegister] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [customerName, setCustumerName] = useState("");
  const [custumerRefCode, setCustumerRefCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState("");

  return (
    <>
      {!register ? (
        <Signup
          refCode={refCode}
          setRegister={setRegister}
          showModal={showModal}
          setShowModal={setShowModal}
          setErrorModal={setErrorModal}
          setCustumerName={setCustumerName}
          setCustumerRefCode={setCustumerRefCode}
          setError={setError}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          setRefCode={setRefCode}
        />
      ) : (
        <Success
          setShowModal={setShowModal}
          setRegister={setRegister}
          errorModal={errorModal}
          showModal={showModal}
          customerName={customerName}
          custumerRefCode={custumerRefCode}
          setErrorMessage={setErrorMessage}
          errorMessage={errorMessage}
          error={error}
        />
      )}
    </>
  );
}

export default Modal;
