import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { TextField, NativeSelect, InputLabel } from "@material-ui/core";
import {
  SingupCustomer,
  GenerateOTP,
  ListCustomers,
  listCountries
} from "../queries";
import signupImage from "../../assets/signupPahe.png";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import otpimg from "../../assets/otp-verification.png";
import OtpInput from "react-otp-input";
import { Captcha } from 'primereact/captcha';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  backBTN: {
    position: "absolute",
    left: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    fontSize: "16px",
    background: "transparent",
    border: "none",
    outline: "none",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, OTP, setOTP, setBack, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Box className="modal-header-custom">{children}</Box>
      {OTP ? (
        <IconButton
          aria-label="back"
          className={classes.backBTN}
          onClick={() => {
            setOTP(false);
            setBack(true);
          }}
        >
          <ArrowBackIcon />
        </IconButton>
      ) : null}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function Signup({
  setRegister,
  setShowModal,
  setCustumerName,
  setCustumerRefCode,
  setErrorMessage,
  errorMessage,
  refCode,
  showModal,
}) {
  const handleClose = () => {
    if ((location.pathname = `/signup/${ref_code}`)) {
      history.push("/");
      setShowModal(false);
      setErrorMessage("");
      setErrorFieldMobile("")
      setErrorFieldEmail("")
      setErrorFieldRef("")
      setNationality("")
      setCode("+971")
      setCapcha(false);
    } else {
      setShowModal(false);
      setErrorMessage("");
      setErrorFieldMobile("")
      setErrorFieldEmail("")
      setErrorFieldRef("")
      setNationality("")
      setCode("+971")
      setCapcha(false);
    }
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [singupCustomer] = useMutation(SingupCustomer);
  const [generateOTP] = useMutation(GenerateOTP);
  const { data: dataCustomer, refetch: refetchCustomer } = useQuery(ListCustomers);
  const { data: dataCountries } = useQuery(listCountries);
  const [countries, setCountries] = useState([]);
  const [code, setCode] = useState("+971");
  const [nationality, setNationality] = useState("");
  const [OTP, setOTP] = useState(false);
  const [OTPInput, setOTPInput] = useState(false);
  const [OTPValue, setOTPValue] = useState(0);
  const [customer, setCustomer] = useState({});
  const [listCustomers, setListCustomers] = useState([]);
  const [back, setBack] = useState(false);
  const [second, setSecond] = useState(60);
  const [errorFieldEmail, setErrorFieldEmail] = useState("");
  const [errorFieldMobile, setErrorFieldMobile] = useState("");
  const [errorFieldRef, setErrorFieldRef] = useState("");
  const [capcha, setCapcha] = useState(false);

  const history = useHistory();
  const location = useLocation();
  let { ref_code } = useParams();

  useEffect(() => {
    if (dataCountries?.listCountries) {
      setCountries(dataCountries?.listCountries);
    }
  }, [dataCountries]);

  useEffect(() => {
    if (second > 0 && OTP) {
      setTimeout(() => setSecond(second - 1), 1000);
    }
  }, [second, OTP]);

  useEffect(() => {
    setListCustomers(dataCustomer?.listCustomers);
    refetchCustomer();
  }, [dataCustomer, refetchCustomer, listCustomers]);

  if (OTP) {
    return (
      <Dialog
        fullWidth
        scroll="body"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showModal}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          OTP={OTP}
          setOTP={setOTP}
          setBack={setBack}
        >
          <img src={otpimg} alt="Instagram QR Code" className="ModalImage" />
          <h5 className="modal-title">OTP Verification</h5>
        </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            paddingTop: 0,
          }}
        >
          {errorMessage ? (
            <span className="modal-title mb-2" style={{ color: "red" }}>
              {errorMessage}
            </span>
          ) : null}
          <span className="modal-title mb-2">
            Enter OTP sent to your Mobile.
          </span>
          <form className="modelForm">
            <div className="form-group" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <OtpInput
                value={OTPValue}
                onChange={async (OTPValue) => {
                  setOTPValue(OTPValue);
                  setErrorMessage("")
                }}
                numInputs={4}
                shouldAutoFocus
                isDisabled={OTPInput}
                isInputNum
                inputStyle={{
                  width: "50px",
                  color: "black",
                  caretColor: "transparent",
                }}
                separator={<span>-</span>}
              />
            </div>
            <span className="modal-title">
              Did not receive OTP?{" "}
              <button
                type="button"
                className={second <= 0 ? "resent-btn" : "resent-timer"}
                onClick={async (e) => {
                  e.preventDefault();
                  setSecond(60);
                  try {
                    await generateOTP({
                      variables: {
                        source: customer.mobile,
                        source_type: 1,
                      },
                    });
                    await setErrorMessage("");
                  } catch (error) {
                    await setErrorMessage(error.message);
                  }
                }}
                disabled={second !== "Resend"}
              >
                {second !== "Resend" ? <span>{second} Seconds..</span> : second}
              </button>
            </span>
            <div
              className="form-group"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                type="submit"
                className="btn mt-2"
                disabled={OTPValue.toString().length !== 4}
                onClick={async (e) => {
                  console.log(e)
                  e.target.disabled=true
                  e.preventDefault();
                  if (OTPValue.toString().length === 4) {
                    setOTPInput(true);
                    try {
                      const res = await singupCustomer({
                        variables: {
                          username: customer.name,
                          email: customer.email,
                          mobile: customer.mobile,
                          OTP: Number(OTPValue),
                          referral_code: customer.referral_code,
                          nationality: customer.nationality,
                          age: customer.age,
                          gender: customer.gender,
                        },
                      });
                      setCapcha(false);
                      setRegister(true);
                      await setCustumerName(
                        res.data.singupCustomer.firstname
                      );
                      await setCustumerRefCode(
                        res.data.singupCustomer.referral_code
                      );
                      await setErrorMessage(
                        res.data.singupCustomer.message
                      );
                      setSecond(60);
                      e.target.disabled=false
                    } catch (error) {
                      setOTPValue(0);
                      await setErrorMessage(error.message);
                      e.target.disabled=false
                    }
                    setOTPInput(false);
                  }
                }}
              >
                Verify
              </button>
            </div>
          </form>
          <div className="dots">
            <span className="dot-active"></span>
            <span className="dot-space"></span>
            <span className="dot-active"></span>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        scroll="body"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showModal}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          OTP={OTP}
          setOTP={setOTP}
        >
          <img src={signupImage} alt="signupImage" className="signupImage" />
        </DialogTitle>
        <DialogContent>
          <p style={{ fontSize: "14px", textAlign: "center" }}>
            Currently registration is exclusively by using Referral Code.
          </p>
          <Formik
            initialValues={{
              referral_code: refCode || customer?.referral_code,
              name: "" || customer?.name,
              email: "" || customer.email,
              mobile: "" || customer.mob,
              nationality: "" || customer.nationality,
              age: "" || customer.age,
              gender: "" || customer.gender || "Male",
            }}
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={async (values) => {
              try {
                let customerExist = await listCustomers.find(
                  (x) => x.mobile === `${code}${values.mobile}`
                );
                let customerRef;
                if(values.referral_code){
                  customerRef = await listCustomers.find(
                    (x) => x.referral_code.toUpperCase() === values.referral_code.toUpperCase()
                  );
                }
                if (capcha) {
                  if (!values.referral_code || (values.referral_code && customerRef)) {
                    if (values.mobile !== customer?.mob) {
                      if (!customerExist?.mobile || !customerExist?.email) {
                        const res = await generateOTP({
                          variables: {
                            source: `${code}${values.mobile}`,
                            source_type: 1,
                          },
                        });
                        if (res.data.generateOTP.id) {
                          setCustomer({
                            name: values.name,
                            email: values.email,
                            mobile: `${code}${values.mobile}`,
                            referral_code: values.referral_code,
                            nationality,
                            age: values.age,
                            gender: values.gender,
                            callingCode: code,
                            mob: values.mobile,
                          });
                        }
                        setOTP(true);
                        setSecond(60);
                        setErrorMessage("");
                      } else {
                        console.log("You are already registered")
                        setErrorMessage("You are already registered");
                        setCustumerName(customerExist.firstname);
                        setCustumerRefCode(customerExist.referral_code);
                        setRegister(true);
                      }
                    } else {
                      setCustomer({
                        name: values.name,
                        email: values.email,
                        mobile: `${code}${values.mobile}`,
                        referral_code: values.referral_code,
                        nationality,
                        age: values.age,
                        gender: values.gender,
                        callingCode: code,
                        mob: values.mobile,
                      });
                      setOTP(true);
                      setSecond(60);
                    }
                  } else {
                    console.log("Referral code is not exist")
                    setErrorMessage("Referral code is not exist");
                  }
                } else {
                  console.log("Pleace complete captcha below")
                  setErrorMessage("Pleace complete captcha below");
                }
              } catch (error) {
                console.log(error)
                await setErrorMessage(error.message);
              }
            }}
            validationSchema={Yup.object({
              referral_code: Yup.string(),
              name: Yup.string()
                .required("Name is required")
                .min(3, "User Name should be greater or equal to 3 charachter"),
              email: Yup.string().email().required("Email is required"),
              mobile: Yup.string()
                .required("Mobile no. is required")
                .matches(
                  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                  "Mobile No. is incorrect"
                ),
            })}
          >
            {(formik) => {
              return (
                <Form className="modelForm" onSubmit={formik.handleSubmit}>
                  <div className="form-group">
                    <Field
                      as={TextField}
                      label="Referral Code"
                      fullWidth
                      autoComplete="off"
                      name="referral_code"
                      type="text"
                      error={formik.errors.referral_code}
                      onBlur={async (e) => {
                        formik.handleBlur(e);
                        let error = await listCustomers.find(
                          (x) => x.referral_code.toUpperCase() === e.target.value.toUpperCase()
                        );
                        if (!error && e.target.value.length !== 0) {
                          setErrorFieldRef("Referral code is not exist");
                        } else {
                          setErrorFieldRef("");
                        }
                      }}
                    />
                    {errorFieldRef || errorMessage === "Referral code is not exist" ? (
                      <span className="error">{errorFieldRef || errorMessage}</span>
                    ) : null}
                    {!errorFieldRef ? (
                      errorFieldRef ? (
                        <CheckIcon className="fieldIcon pass" />
                      ) : null
                    ) : errorFieldRef ? (
                      <ClearIcon className="fieldIcon clear" />
                    ) : null}
                  </div>
                  <div className="form-group">
                    <Field
                      as={TextField}
                      label="Name *"
                      fullWidth
                      autoComplete="off"
                      name="name"
                      type="text"
                      error={formik.errors.name}
                    />
                    <ErrorMessage
                      component="div"
                      name="name"
                      className="error"
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      as={TextField}
                      label="Email *"
                      fullWidth
                      autoComplete="off"
                      name="email"
                      type="email"
                      error={formik.errors.email}
                      onBlur={async (e) => {
                        formik.handleBlur(e);
                        let error = await listCustomers.find(
                          (x) => x.email === e.target.value
                        );
                        if (error && e.target.value.length !== 0) {
                          setErrorFieldEmail("This email is already registered with us");
                        } else {
                          setErrorFieldEmail("")
                        }
                      }}
                    />
                    {errorFieldEmail ? (
                      <span className="error">{errorFieldEmail}</span>
                    ) : null}
                    {!errorFieldEmail ? (
                      errorFieldEmail ? (
                        <CheckIcon className="fieldIcon pass" />
                      ) : null
                    ) : errorFieldEmail ? (
                      <ClearIcon className="fieldIcon clear" />
                    ) : null}
                  </div>
                  <div className="form-group" style={{ marginTop: "10px" }}>
                    <InputLabel htmlFor="nationality">Nationality *</InputLabel>
                    <NativeSelect
                      id="nationality"
                      name="nationality"
                      fullWidth
                      value={nationality}
                      onChange={(e) => {
                        setNationality(e.target.value);
                      }}
                    >
                      <option value="">Select</option>
                      {countries.map((country, id) => (
                        <option value={country.name} key={id}>
                          {country.name}
                        </option>
                      ))}
                    </NativeSelect>
                  </div>
                  <div className="form-group">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <input
                        className="code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        readOnly
                        type="text"
                      />
                      <Field
                        as={TextField}
                        fullWidth
                        autoComplete="off"
                        label="Mobile *"
                        id="mobile"
                        name="mobile"
                        type="tel"
                        error={formik.errors.mobile}
                        onBlur={async (e) => {
                          formik.handleBlur(e);
                          let error = await listCustomers.find(
                            (x) => x.mobile === `${code}${e.target.value}`
                          );
                          if (error && e.target.value.length !== 0) {
                            setErrorFieldMobile("This number is already registered with us");
                          } else {
                            setErrorFieldMobile("");
                          }
                        }}
                      />
                    </div>
                    {errorMessage === "Invalid Mobile No!" && !errorFieldMobile ? (
                      <span className="error">Mobile No. is Invalid!</span>
                    ) : null}
                    {errorMessage === "Error: Invalid country calling code" && !errorFieldMobile ? (
                      <span className="error">Country calling code is Invalid!</span>
                    ) : null}
                    {errorFieldMobile ? (
                      <span className="error">{errorFieldMobile}</span>
                    ) : null}
                    {!errorFieldMobile ? (
                      errorFieldMobile ? (
                        <CheckIcon className="fieldIcon pass" />
                      ) : null
                    ) : errorFieldMobile ? (
                      <ClearIcon className="fieldIcon clear" />
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label htmlFor="age">Age</label>
                    <div className="ageSliderContainer">
                      <label className="ageSlider">
                        18 to 30
                        <Field type="radio" name="age" value="18-30" />
                        <span className="checkmark"></span>
                      </label>
                      <span style={{ width: "10px" }}></span>
                      <label className="ageSlider">
                        31 to 40
                        <Field type="radio" name="age" value="31-40" />
                        <span className="checkmark"></span>
                      </label>
                      <span style={{ width: "10px" }}></span>
                      <label className="ageSlider">
                        41 to 50
                        <Field type="radio" name="age" value="41-50" />
                        <span className="checkmark"></span>
                      </label>
                      <span style={{ width: "10px" }}></span>
                      <label className="ageSlider">
                        Above 50
                        <Field type="radio" name="age" value="above 50" />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label style={{ marginRight: "10px" }}>Gender</label>
                    <div className="form-check form-check-inline">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name="gender"
                        id="male"
                        value="Male"
                      />
                      <label className="form-check-label" htmlFor="male">
                        Male
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name="gender"
                        id="female"
                        value="Female"
                      />
                      <label className="form-check-label" htmlFor="female">
                        Female
                      </label>
                    </div>
                  </div>
                  <div
                    className="form-group">
                    <Captcha id="captcha" siteKey={process.env.REACT_APP_GOOGLE_SITE_KEY} onResponse={() => setCapcha(true)} />
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      type="submit"
                      className="btn"
                      disabled={
                        formik.isSubmitting ||
                        !(formik.isValid &&
                          formik.dirty) ||
                        errorFieldEmail ||
                        errorFieldRef ||
                        errorFieldMobile ||
                        !nationality ||
                        !capcha
                      }
                    >
                      {!formik.isSubmitting ? (
                        <span>{!back ? "Sign up" : "Next"}</span>
                      ) : (
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {!back ? "Sign up" : "Next"}{" "}
                          <span
                            className="spinner-border text-light"
                            style={{ marginLeft: "10px" }}
                          ></span>
                        </span>
                      )}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
          <div className="dots">
            <span className="dot-active"></span>
            <span className="dot-space"></span>
            <span className="dot-disable"></span>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
