import { useState, useEffect } from "react";
import { useLocation, 
  // useParams, 
  useHistory } from "react-router-dom";
import Contact from "./Contact";
import Footer from "./Footer";
import QRDownload from "./QRDownload";
import Aboutus from "./Aboutus";
import Header from "./Header";
import SignupModal from "./Signup/SignupModal";
import SocialLinksModal from "./SocialLinks";
import TermAndCondModal from "./TermAndCondModal";

function Layout(props) {
  const [refCode, setRefCode] = useState("");
  const [showModal, setShowModal] = useState(false);
  let history = useHistory();
  let location = useLocation();
  // let { ref_code } = useParams();

  useEffect(() => {
    const ref = location.pathname.split("/")
    if (
      ref[2] &&
      ref[2].length === 6 &&
      location.pathname === `/signup/${ref[2]}`
    ) {
      setRefCode(ref[2]);
      setShowModal(true);
    } else if (ref[2] && ref[2].length !== 6) {
      history.push("/404");
    }
  }, [location.pathname, history]);

  return (
    <div>
    <div className="container">
      <Header setShowModal={setShowModal} />
    </div>
    <div >
      {props.children}
    </div>
    <div className="container">
      <Contact />
      <QRDownload />
      <SocialLinksModal />
      <Aboutus />
      <SignupModal
        showModal={showModal}
        setShowModal={setShowModal}
        setRefCode={setRefCode}
        refCode={refCode}
        />
      <TermAndCondModal />
      <Footer />
    </div>

    </div>
  );
}

export default Layout;
