import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import ClearIcon from "@material-ui/icons/Clear";
import logo from "../assets/logo.png";
import { handleOpenModal } from '../store/slices'
import { useDispatch } from 'react-redux'

function Header({setShowModal}) {
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch()
  return (
    <nav className="navbar navbar-expand-sm">
      <div className="container-fluid">
        <Link to="/">
          <img className="navbar-brand" src={logo} alt="logo" />
        </Link>
        <button
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
        >
          {!toggle ? (
            <MenuIcon onClick={() => setToggle(true)} />
          ) : (
            <ClearIcon onClick={() => setToggle(false)} />
          )}
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/"
                activeClassName="selected-nav-link"
                exact 
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="aboutus"
                activeClassName="selected-nav-link"
                exact 
              >
                About us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="partnership"
                activeClassName="selected-nav-link"
                exact 
              >
                Our Partnerships
              </NavLink>
            </li>
            <li className="nav-item">
              <button
                className="nav-link"
                onClick={() => dispatch(handleOpenModal("contactus"))}
              >
                Contact
              </button>
            </li>
           <li className="nav-item">
              <button
                onClick={() => setShowModal(true)}
                className="nav-link"
              >
                Register
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link"
                onClick={() => dispatch(handleOpenModal("connect"))}
              >
                Download App
              </button>
            </li>
            <li className="nav-item social-link">
              <button
                className="nav-link"
                onClick={() => dispatch(handleOpenModal("socialLinks"))}
              >
                Social Link
              </button>
            </li>
          </ul>
        </div>
        <div className="navbar-nav-social-link">
            <a href="https://www.facebook.com/bigboxsouqdubai/?show_switched_toast=0&show_switched_tooltip=0&show_podcast_settings=0" rel="noreferrer" target="_blank"><i className="fab fa-facebook"></i></a>
            <span style={{width: "10px"}}></span>
            {/* <a href="https://api.whatsapp.com/send?phone=+971585534118" rel="noreferrer" target="_blank"><i className="fab fa-whatsapp"></i></a> */}
            <a href="https://wa.me/971585534118" rel="noreferrer" target="_blank"><i className="fab fa-whatsapp"></i></a>
            {/* <a href="https://api.whatsapp.com/send?phone=+971585534118&text=urlencodedtext" rel="noreferrer" target="_blank">test</a> */}

            <span style={{width: "10px"}}></span>
            <a href="https://www.instagram.com/bigboxsouq/" rel="noreferrer" target="_blank"><i className="fab fa-instagram"></i></a>
            <span style={{width: "10px"}}></span>
            <a href="mailto:hi@bigboxsouq.com" rel="noreferrer" target="_blank"><i className="fas fa-envelope"></i></a>
        </div>
      </div>
    </nav>
  );
}

export default Header;
