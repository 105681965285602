import faq1 from "../assets/faq1.png";
import faq2 from "../assets/faq2.png";
import faq3 from "../assets/faq3.png";
import { useLocation } from "react-router-dom";

function TermAndCondPage() {
  const location = useLocation();
  
  return (
    <div className="faqs container">
      {
        location.pathname === "/termandcondition" ? (
        <div className="faqImages">
          <div className="faqImages-page">
          <img src={faq1} alt="faq" />
          </div>
          <div className="faqImages-page">
          <img src={faq2} alt="faq"/>
          </div>
          <div className="faqImages-page">
          <img src={faq3} alt="faq"/>
          </div>
        </div>
        ) : (
        <div className="faqImages">
          <div className="faqImages-modal">
          <img src={faq1} alt="faq" />
          </div>
          <div className="faqImages-modal">
          <img src={faq2} alt="faq"/>
          </div>
          <div className="faqImages-modal">
          <img src={faq3} alt="faq"/>
          </div>
        </div>
        )}
      <div>
        <p className="faq-title">
          <b>
            <span>TERMS AND CONDITIONS &amp; PRIVACY POLICY</span>
          </b>
        </p>

        <p>
          <b>
            <span>
              1.
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>PREAMBLE</span>
            </u>
          </b>
        </p>

        <p>
          <span>
            1.1.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Welcome to BigBoxSouq.com, this website &amp; mobile app (the App)
            is provided by Big Box Souq Portal LLC (We or US) as a service to
            our customers.{" "}
          </span>
        </p>

        <p>
          <span>
            1.2.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Please note that your use of the App service constitutes your
            unconditional agreement to follow and be bound by these terms and
            conditions &amp; privacy policies (the Terms and Conditions). Please
            read the following carefully as they affect your rights and
            liabilities under the law
          </span>
        </p>

        <p>
          <span>
            1.3.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            We reserve the right to change the Terms and Conditions at any time.
            Any such changes will take effect when posted on the App.
          </span>
        </p>

        <p>
          <span>
            1.4.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            It is your responsibility to read the Terms and Conditions on each
            occasion you use this App and your continued use of the App and its
            services shall signify your acceptance to be bound by the latest
            Terms and Conditions.
          </span>
        </p>

        <p>
          <span>
            1.5.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            If you do not agree to (or cannot comply with) any of the Terms and
            Conditions, do not use this App.
          </span>
        </p>

        <p>
          <span>
            1.6.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            All the Information (as defined below) provided must be accurate.
            Providing any inaccurate Information constitutes a breach of these
            Terms and Conditions.{" "}
          </span>
        </p>

        <p>
          <span>
            1.7.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            By confirming your purchase at the end of the checkout process, you
            agree to accept and pay for the item(s) requested.
          </span>
        </p>

        <p>
          <span>
            1.8.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            If you are not a consumer, you confirm that you have authority to
            bind any business.
          </span>
        </p>

        <p>
          <span>
            1.9.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            The colors of our products that appear on the App will be displayed
            as accurately as possible. However, as the actual colors you see
            will depend on your screen, we cannot guarantee that your screen's
            display of any color will be accurate.
          </span>
        </p>

        <p>
          <span>1.10.</span>
          <span>
            Our platforms are not directed towards children. If you are
            providing personal information for an individual less than 18 years
            of age, you are providing us your affirmative parental consent as
            the legal parent or guardian to collect, use and process the
            information of the individual less than 18 years of age, consistent
            with this Policy.
          </span>
        </p>

        <p>
          <span>1.11.</span>
          <span>You agree to transact through this App at your sole risk.</span>
        </p>

        <p>
          <b>
            <span>
              2.
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>TERMS OF USE</span>
            </u>
          </b>
        </p>

        <p>
          <span>
            2.1.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            The App can be used only by persons who can form legally binding
            contracts under applicable law. Persons who are incompetent to
            contract are not eligible to use the App. If your age is below that
            of 18 years your parents or legal guardians can transact on behalf
            of you if they are registered users/members.{" "}
          </span>
        </p>

        <p>
          <span>
            2.2.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            We reserve the right to terminate your registration and refuse to
            provide you with access to the App if We discovers that you are
            under the age of 18 years.
          </span>
        </p>

        <p>
          <span>
            2.3.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            The App cannot be accessed to persons whose registration has been
            suspended or terminated by us for any reason whatsoever.{" "}
          </span>
        </p>

        <p>
          <span>
            2.4.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            We aim to offer a fault-free service. However, if a fault occurs in
            the service, you may report it to us and we will attempt to correct
            the fault as soon as we reasonably can.
          </span>
        </p>

        <p>
          <span>
            2.5.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Access to the App may be occasionally restricted to allow for
            repair, maintenance or the introduction of new facilities or
            services.
          </span>
        </p>

        <p>
          <span>
            2.6.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Those who choose to access this App from outside the United Arab
            Emirates are responsible for compliance with local laws if and to
            the extent local laws are applicable.{" "}
          </span>
        </p>

        <p>
          <span>
            2.7.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            We will deliver the products only within the UAE and will not be
            liable for any claims relating to any products ordered from outside
            its jurisdiction.
          </span>
        </p>

        <p>
          <b>
            <span>
              3.
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>LIMITATIONS (APP SECURITY)</span>
            </u>
          </b>
        </p>

        <p>
          <span>
            3.1.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            You are prohibited from violating the security of the App,
            including, without limitation:
          </span>
        </p>

        <p>
          <span>
            3.1.1.<span></span>
          </span>
          <span>Gaining unauthorised access to other computer systems</span>
        </p>

        <p>
          <span>
            3.1.2.<span></span>
          </span>
          <span>Interfering with any other person's use of the App</span>
        </p>

        <p>
          <span>
            3.1.3.<span></span>
          </span>
          <span>Impersonating another person</span>
        </p>

        <p>
          <span>
            3.1.4.<span></span>
          </span>
          <span>accessing data not intended for such user</span>
        </p>

        <p>
          <span>
            3.1.5.<span></span>
          </span>
          <span>
            logging onto a server or an account which the user is not authorized
            to access.
          </span>
        </p>

        <p>
          <span>
            3.1.6.<span></span>
          </span>
          <span>
            attempting to probe, scan or test the exposure of our Apps to breach
            security or authentication measures without proper authorization.
          </span>
        </p>

        <p>
          <span>
            3.1.7.<span></span>
          </span>
          <span>
            attempting to interfere with service to any user, host or network,
            including, without limitation, via means of submitting a virus to
            the Website, overloading, flooding, spamming, mail bombing or
            crashing.
          </span>
        </p>

        <p>
          <span>
            3.1.8.<span></span>
          </span>
          <span>sending unsolicited email</span>
        </p>

        <p>
          <span>
            3.1.9.<span></span>
          </span>
          <span>
            Making, transmitting, or storing electronic copies of materials
            protected by copyright without written permission from us
          </span>
        </p>

        <p>
          <span>
            3.1.10.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Referring to specific website addresses outside of the App
          </span>
        </p>

        <p>
          <span>
            3.1.11.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Interfering or disrupting networks or websites connected to the App
          </span>
        </p>

        <p>
          <span>
            3.1.12.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>Advertising</span>
        </p>

        <p>
          <span>
            3.1.13.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Violations of system or network security may result in civil or
            criminal liability.{" "}
          </span>
        </p>

        <p>
          <span>
            3.1.14.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            We will investigate such violations and may prosecute users who are
            involved in such violations.{" "}
          </span>
        </p>

        <p>
          <span>
            3.2.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            You agree not to use any device, software or routine to interfere or
            attempt to interfere with the proper working of this App or any
            activity being conducted on this Website.{" "}
          </span>
        </p>

        <p>
          <span>
            3.3.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            You agree, further, not to use or attempt to use any engine,
            software, tool, agent or other device or mechanism (including
            without limitation browsers, spiders, robots, data scrappers,
            avatars or intelligent agents) to navigate or search this App other
            than the search engine and search agents available from us on this
            App.
          </span>
        </p>

        <p>
          <span>
            3.4.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            You will be responsible for all losses, costs and expenses
            reasonably incurred by us, all damages awarded against us by a court
            and all sums paid by us as a result of any settlement agreed by us
            arising out or in connection with:
          </span>
        </p>

        <p>
          <span>
            3.4.1.<span></span>
          </span>
          <span>
            Any claim by any third party that the use of the App by you is
            defamatory, offensive or abusive, or of an obscene or pornographic
            nature, or is illegal or is in breach of any applicable law,
            regulation or code of practice; and
          </span>
        </p>

        <p>
          <span>
            3.4.2.<span></span>
          </span>
          <span>
            Any claim by any third party that the use of the App by you
            infringes that third party's copyright or other intellectual
            property rights of whatever nature.
          </span>
        </p>

        <p>
          <span>
            3.5.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            By submitting material to us, you are granting us a perpetual,
            royalty-free, non-exclusive licence to reproduce, modify, translate,
            make available, distribute and sublicense the material in whole or
            in part and in any form.
          </span>
        </p>

        <p>
          <b>
            <span>
              4.
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>THIRD PARTY WEBSITES</span>
            </u>
          </b>
        </p>

        <p>
          <span>
            4.1.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            As a convenience to App customers, we may include links to other
            websites or material to promote our partner brands. We are not
            responsible for any of these websites or material, which is beyond
            our control.
          </span>
        </p>

        <p>
          <span>
            4.2.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Third party advertisers and partners are responsible for ensuring
            that material submitted for inclusion on the App complies with
            relevant laws and codes. We will not be responsible for any error or
            inaccuracy in advertising and sponsorship material provided by third
            parties
          </span>
        </p>

        <p>
          <b>
            <span>
              5.
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>REGISTRATION PROCESS</span>
            </u>
          </b>
        </p>

        <p>
          <span>
            5.1.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            To register on the App you must be over eighteen (18) years of age.
          </span>
        </p>

        <p>
          <span>
            5.2.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            You must ensure that the details provided by you at any other time
            are correct and complete
          </span>
        </p>

        <p>
          <span>
            5.3.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            The information (the Information) provided from your end will be
            protected in accordance with our Privacy Policy.{" "}
          </span>
        </p>

        <p>
          <span>
            5.4.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            If you use the App, you are responsible for maintaining the
            confidentiality of your account and password/OTP and for restricting
            access to your computer
          </span>
        </p>

        <p>
          <span>
            5.5.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            You agree to accept responsibility for all activities that occur
            under your account or password.
          </span>
        </p>

        <p>
          <span>
            5.6.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            We assume no liability to any person for any loss or damage which
            may arise as a result of any failure by you in protecting your
            password or account.{" "}
          </span>
        </p>

        <p>
          <span>
            5.7.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Any suspicious activities on your account shall be notified to us by
            contacting us immediately through the address provided below.{" "}
          </span>
        </p>

        <p>
          <span>
            5.8.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            If we have reason to believe that there is likely to be a breach of
            security or misuse of the App, we may require you to change your
            password or we may suspend your account without any liability to us.
          </span>
        </p>

        <p>
          <b>
            <span>
              6.
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>PRODUCT PRICING</span>
            </u>
          </b>
        </p>

        <p>
          <span>
            6.1.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Errors may occur in the process of providing product and pricing
            information by the App. We cannot confirm the price of a product
            except after ordering it.{" "}
          </span>
        </p>

        <p>
          <span>
            6.2.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            In the event that a product is listed at an incorrect price or with
            incorrect information due to an error in pricing or product
            information, we will notify the correct price to you so you can
            decide whether or not you wish to order the product at that price.{" "}
          </span>
        </p>

        <p>
          <span>
            6.3.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            If you decide not to order the product, we will give you a full
            refund on any amount already paid for that product in accordance
            with our refund policy. We shall have the right, at our sole
            discretion, to either contact you for instructions or cancel your
            order and notify you of such cancellation.
          </span>
        </p>

        <p>
          <span>
            6.4.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            In the event that the App accepts your order, the same shall be
            debited to your credit card account and duly notified to you by
            email. The payment may be processed prior to our dispatch of the
            product that you have ordered. If we have to cancel the order after
            we have processed the payment, the said amount will be reversed back
            to your credit/debit card account. No cash disbursement shall be
            made under any condition whatsoever.
          </span>
        </p>

        <p>
          <span>
            6.5.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            The price of a product does not include the delivery charge, which
            will depend on the delivery method you choose and will be added
            during checkout.
          </span>
        </p>

        <p>
          <span>
            6.6.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Prices and availability are subject to change without any prior
            notice. Further, description/performance of products stated against
            each are based on the catalogue and technical literature printed by
            the manufacturers/agent. Therefore, the write-up provided against
            each product is not that of ours and subject to change without prior
            notice.
          </span>
        </p>

        <p>
          <span>
            6.7.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            All customers will be charged in UAE Dirham and differences between
            approximation in any foreign currency and actual billing price on
            the customer's credit card bill is probably attributable to
            different credit card companies charging different exchange rates.
          </span>
        </p>

        <p>
          <b>
            <span>
              7.
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>ORDER ACCEPTANCE</span>
            </u>
          </b>
        </p>

        <p>
          <span>
            7.1.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            We reserve the right, at its sole discretion, to refuse or cancel
            any order for any reason whatsoever.
          </span>
        </p>

        <p>
          <span>
            7.2.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Limitations on quantities available for purchase inaccuracies or
            errors in product or pricing information, or problems identified by
            our credit and fraud are some situations that may result in the
            order being cancelled, additional verifications or information may
            be requested before accepting any order.{" "}
          </span>
        </p>

        <p>
          <span>
            7.3.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            We will contact you if all or any portion of your order is cancelled
            or if additional information is required to accept your order.{" "}
          </span>
        </p>

        <p>
          <span>
            7.4.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            If your order is cancelled after your credit/debit card has been
            charged, the said amount will be reversed back in your credit/debit
            card account. No cash disbursement shall be made under any condition
            whatsoever.
          </span>
        </p>

        <p>
          <span>
            7.5.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Once the order leaves our logistics center, you will be notified
            about the delivery.
          </span>
        </p>

        <p>
          <span>
            7.6.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            It is the responsibility of the customer to ensure that they are
            present at time of delivery.
          </span>
        </p>

        <p>
          <span>
            7.7.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Your order is an offer to buy from us. You can track your order
            details in the App{" "}
          </span>
        </p>

        <p>
          <span>
            7.8.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Nothing that we do or say will amount to any acceptance of your
            offer until we send you an email notifying you of the order
            acknowledgment. At this point, a contract will be made between us
            for you to buy and for us to sell the products that you have ordered
            from us.
          </span>
        </p>

        <p>
          <span>
            7.9.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            After the time the contract is made, you cannot amend your order
            (please see your right to cancel an order).
          </span>
        </p>

        <p>
          <span>7.10.</span>
          <span>
            At any point up until the contract is made, we may decline to supply
            a product to you. If we decline to supply a product to you and you
            have already paid for it, we will give you a full refund of any
            amount already paid for that product in accordance with our refund
            policy.
          </span>
        </p>

        <p>
          <b>
            <span>
              8.
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>CREDIT/DEBIT CARD PAYMENTS </span>
            </u>
          </b>
        </p>

        <p>
          <span>
            8.1.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Payment for your products will be taken at the time you place your
            order.{" "}
          </span>
        </p>

        <p>
          <span>
            8.2.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Payments are done via professional third-party cyber source payment
            gateway.
          </span>
        </p>

        <p>
          <span>
            8.3.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            All customers will be charged in local currency relating to the
            country of order.{" "}
          </span>
        </p>

        <p>
          <span>
            8.4.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            We reserve the right to refuse to accept the Credit/Debit Card or,
            in such case the transaction is accepted, cancel the transaction, at
            our sole discretion, as part of our own fraud detection program.
          </span>
        </p>

        <p>
          <span>
            8.5.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            The card details provided by you for benefiting from the services on
            the App must be truthful, valid and accurate and you shall use the
            card which is lawfully owned by you{" "}
          </span>
        </p>

        <p>
          <span>
            8.6.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            We will not be liable for any card fraud. The liability for use of a
            card fraudulently will be on you and the responsibility to prove
            otherwise shall be exclusively on you.
          </span>
        </p>

        <p>
          <span>
            8.7.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            All cards details and personally identifiable information will NOT
            be stored, sold, shared, rented or leased to any third parties
          </span>
        </p>

        <p>
          <b>
            <span>
              9.
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>DELIVERIES</span>
            </u>
          </b>
        </p>

        <p>
          <span>
            9.1.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            In the event of a non-delivery occurs on account of a mistake by you
            (i.e. wrong name or address or any other wrong information), any
            extra cost incurred by us for redelivery shall be claimed from you.
          </span>
        </p>

        <p>
          <span>
            9.2.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Orders maybe shipped out by courier or supplier deliveries.
          </span>
        </p>

        <p>
          <span>
            9.3.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Delivery is normally door-to-door but there may be instances where
            this is not possible due to limitations or local laws.
          </span>
        </p>

        <p>
          <span>
            9.4.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            You will provide correct information requested from your side. We
            reserve the right to confirm and validate the information and other
            details provided by you at any point of time. If upon confirmation
            your details are found not to be true, we have the right, in our
            sole discretion to reject the registration and ban you from using
            the services of the App.
          </span>
        </p>

        <p>
          <span>
            9.5.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            That correct and proper address at which delivery of the product
            ordered by you shall be provided by you. If a recipient's address is
            found to be incomplete or incorrect, we may attempt to find the
            correct address and to complete the delivery, but we assume no
            responsibility for our inability to complete delivery under such
            circumstances.
          </span>
        </p>

        <p>
          <span>
            9.6.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            If the address is incorrect, or if delivery can't be completed, then
            a surcharge for extra cost will be imposed to the customer.
          </span>
        </p>

        <p>
          <span>
            9.7.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Before placing an order you will check the product description
            carefully. By placing an order for a product you agree to be bound
            by the conditions of sale included in the item's description.
          </span>
        </p>

        <p>
          <span>
            9.8.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Whilst we make considerable effort to deliver all your products
            within the date &amp; time specified in your order, we shall not be
            liable if we fail to do so in part or in full due to circumstances
            beyond our control. We shall contact you to let you know if we are
            having any problems getting a product to you within that time.{" "}
          </span>
        </p>

        <p>
          <span>
            9.9.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Delivery areas outside of main city limits that are classified as
            remote or semi remote may require a change in delivery time, we will
            notify you of such changes prior to applying them
          </span>
        </p>

        <p>
          <span>9.10.</span>
          <span>
            Delivery will be made to the address specified when you complete the
            order. At point of delivery, identity verification of the Consignee
            by sighting either an original labour card, passport, driving
            license, Emirates ID, Free zone ID to support consignee
            verification, may be required by our courier partners.
          </span>
        </p>

        <p>
          <span>9.11.</span>
          <span>
            During any product delivery, if the client is not available in the
            address specified in the system, the products ordered by the client
            shall not be delivered to anywhere else. Within the framework of
            such a case, the member must accept all legal responsibilities
            arising from ordering a product to an address where he/she does not
            exist.
          </span>
        </p>

        <p>
          <span>9.12.</span>
          <span>
            Based on order size, dimensions and delivery location, a delivery
            cost maybe assigned to the order.{" "}
          </span>
        </p>

        <p>
          <span>9.13.</span>
          <span>Please note that we do not do international shipping</span>
        </p>

        <p>
          <b>
            <span>
              10.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>ORDER CANCELLATIONS BY US</span>
            </u>
          </b>
        </p>

        <p>
          <span>10.1.</span>
          <span>
            Please note that there may be certain orders that we may reject or
            cancel. We reserve the right, at our sole discretion, to refuse or
            cancel any order for any reason.
          </span>
        </p>

        <p>
          <span>10.2.</span>
          <span>
            For your convenience, you will not be charged until your payment
            method is authorized; the order information is verified for
            accuracy.
          </span>
        </p>

        <p>
          <span>10.3.</span>
          <span>
            Some situations that may result in your order being cancelled due to
            limitations on quantities available for purchase, inaccuracies or
            errors in product or pricing information, or problems identified by
            our relevant department.
          </span>
        </p>

        <p>
          <span>10.4.</span>
          <span>
            We may also require additional verifications or information before
            accepting any order. We will contact you if all or any portion of
            your order is cancelled or if additional information is required to
            accept your order. If your order is cancelled after your credit card
            has been charged, we will issue a credit to your credit card in the
            amount of the charge.
          </span>
        </p>

        <p>
          <span>10.5.</span>
          <span>&nbsp;</span>
        </p>

        <p>
          <b>
            <span>
              11.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>ORDER CANCELLATIONS BY THE CUSTOMER</span>
            </u>
          </b>
        </p>

        <p>
          <span>11.1.</span>
          <span>
            If cancellation notice from your side is duly received by us and the
            order has not been processed/approved by us, we shall cancel the
            order and refund the entire amount to your credit/debit card.
          </span>
        </p>

        <p>
          <span>11.2.</span>
          <span>
            Orders that are cancelled after they have been shipped are subject
            to a AED 50 cancellation fee to be deducted from your refund for
            shipping and processing. We have the full right to demonstrate
            whether an order has been shipped or not. The customer agrees not to
            dispute the decision made by us and accept our decision regarding
            the cancellation.
          </span>
        </p>

        <p>
          <b>
            <span>
              12.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>REFUND POLICY</span>
            </u>
          </b>
        </p>

        <p>
          <span>12.1.</span>
          <span>
            If the product is not available, then a full refund will be made to
            the customer
          </span>
        </p>

        <p>
          <span>12.2.</span>
          <span>
            Refund is allowed only once duly approved by our sales team
          </span>
        </p>

        <p>
          <span>12.3.</span>
          <span>
            The product will be refunded at the same price as it was sold to you
          </span>
        </p>

        <p>
          <span>12.4.</span>
          <span>
            The settlement of the refund will only be affected once the bank has
            refunded the money
          </span>
        </p>

        <p>
          <span>12.5.</span>
          <span>The refund will be made in next billing cycle</span>
        </p>

        <p>
          <span>12.6.</span>
          <span>
            Refund will be made through the credit/debit card only for Online
            Credit Card Payment
          </span>
        </p>

        <p>
          <b>
            <span>
              13.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>REFUND CONDITIONS</span>
            </u>
          </b>
        </p>

        <p>
          <span>13.1.</span>
          <span>Products are lost in deliveries</span>
          <span> </span>
        </p>

        <p>
          <span>13.2.</span>
          <span>Products were not delivered </span>
        </p>

        <p>
          <span>13.3.</span>
          <span>
            Items may only be refunded if they are returned within 2 days from
            purchase or delivery date
          </span>
        </p>

        <p>
          <span>13.4.</span>
          <span>
            We will refund the delivery charge in full if you return all
            products of your order at the same time
          </span>
        </p>

        <p>
          <span>13.5.</span>
          <span>
            If you choose to keep some of the products, we may retain the
            balance of the delivery charge
          </span>
        </p>

        <p>
          <span>13.6.</span>
          <span>
            Please keep all items with their complete packaging intact if you
            intend to refund any items within the conditions set out. No
            exchange or refund will be considered without it.
          </span>
        </p>

        <p>
          <span>13.7.</span>
          <span>
            Notwithstanding the terms and conditions herein, items shall be
            exchanged or refunded at our sole discretion{" "}
          </span>
        </p>

        <p>
          <b>
            <span>
              14.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>AVAILABILITY OF PRODUCTS/STOCK</span>
            </u>
          </b>
        </p>

        <p>
          <span>14.1.</span>
          <span>
            All products advertised are subject to availability or while stocks
            last (as may be applicable).
          </span>
        </p>

        <p>
          <span>14.2.</span>
          <span>
            We try to ensure that we always stock the full product range and
            will let you know if the product is out of stock. If for any reason
            beyond our reasonable control we are unable to supply a particular
            product, we will not be liable to you except to ensure that you are
            not charged for that product.
          </span>
        </p>

        <p>
          <span>14.3.</span>
          <span>
            When a product at the time of picking is out of stock, we will
            choose an alternative. If the price of the new product is higher,
            you will not be charged extra &amp; if it's less, you'll pay the
            smaller amount.{" "}
          </span>
        </p>

        <p>
          <span>14.4.</span>
          <span>
            Please note that the &quot;Allow Substitute&quot; option is based on
            the customer's consent during checkout
          </span>
        </p>

        <p>
          <span>14.5.</span>
          <span>
            We reserve the right, at our sole discretion, to limit the quantity
            of items purchased per person, per household or per order.{" "}
          </span>
        </p>

        <p>
          <span>14.6.</span>
          <span>
            The said limitations may be applicable to orders placed by the same
            account, the same credit card, and to orders that use the same
            billing and/or shipping address.{" "}
          </span>
        </p>

        <p>
          <span>14.7.</span>
          <span>
            We will provide notification to the customer should such limits be
            applied.{" "}
          </span>
        </p>

        <p>
          <span>14.8.</span>
          <span>
            We also reserve the right, at its sole discretion, to prohibit sales
            to dealers or resellers.{" "}
          </span>
        </p>

        <p>
          <span>14.9.</span>
          <span>
            For purposes of these Terms and Conditions, reselling shall be
            defined as purchasing or intending to purchase any product(s) from
            our Apps for the purpose of engaging in a commercial sale of that
            same product(s) with a third party.
          </span>
        </p>

        <p>
          <b>
            <span>
              15.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>REWARD POINTS SYSTEM</span>
            </u>
          </b>
        </p>

        <p>
          <span>15.1.</span>
          <span>
            The app runs a Multi Level Referral system reward points for its
            eligible customers
          </span>
        </p>

        <p>
          <span>15.2.</span>
          <span>
            Earning such rewards points and its distribution calculation is at
            our sole discretion
          </span>
        </p>

        <p>
          <span>15.3.</span>
          <span>
            We may from time to time change the reward calculations based on our
            business plan{" "}
          </span>
        </p>

        <p>
          <span>15.4.</span>
          <span>
            Such points are a benefit to our loyal customers, who can redeem
            them to purchase prodyucts &amp; services from the app.
          </span>
        </p>

        <p>
          <span>15.5.</span>
          <span>
            These rewards are not in any form in lieu of cash; ie they cannot be
            claimed or converted to cash at any point of time
          </span>
        </p>

        <p>
          <span>15.6.</span>
          <span>
            You agree that these reward points earned, distributed, and adjusted
            are at our sole discretion and you will not have any say in claiming
            points you may have deemed as earned by yourself.
          </span>
        </p>

        <p>
          <span>15.7.</span>
          <span>
            If you need clarification of your points calculations, you can
            contact us with your queries.
          </span>
        </p>

        <p>
          <b>
            <span>
              16.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>PRIVACY POLICY</span>
            </u>
          </b>
        </p>

        <p>
          <span>16.1.</span>
          <span>We respect your privacy. </span>
        </p>

        <p>
          <span>16.2.</span>
          <span>
            If you have any concerns about our compliance with applicable
            privacy laws please write to us
          </span>
        </p>

        <p>
          <span>16.3.</span>
          <span>
            Every time you interact through our App, you agree to the use of
            information that is collected or submitted as stated in the points
            below
          </span>
        </p>

        <p>
          <span>
            17.
            <span>&nbsp;&nbsp;</span>
          </span>
          <b>
            <u>
              <span>PERSONAL INFORMATION WE COLLECT </span>
            </u>
          </b>
        </p>

        <p>
          <span>17.1.</span>
          <span>
            Personal information you provide about yourself when you register or
            use the App.{" "}
          </span>
        </p>

        <p>
          <span>17.2.</span>
          <span>
            Your contact details, including your physical/email address(es)
            &amp; mobile number(s)
          </span>
        </p>

        <p>
          <span>17.3.</span>
          <span>
            Your interests, preferences, and other profiling information.
          </span>
        </p>

        <p>
          <span>17.4.</span>
          <span>Information about your online purchases </span>
        </p>

        <p>
          <span>17.5.</span>
          <span>
            Information about your online browsing behaviour on our Apps{" "}
          </span>
        </p>

        <p>
          <span>17.6.</span>
          <span>Information about when you click on one of our adverts </span>
        </p>

        <p>
          <span>17.7.</span>
          <span>
            Information about devices you have used to access our Services{" "}
          </span>
        </p>

        <p>
          <span>17.8.</span>
          <span>Information about your precise geolocation.</span>
        </p>

        <p>
          <span>17.9.</span>
          <span>
            Transaction information, including online purchases you earn points
            for and how you use your points
          </span>
        </p>

        <p>
          <span>17.10.</span>
          <span>
            Details of digital communications we send to you including
            information you open and links in them{" "}
          </span>
        </p>

        <p>
          <span>17.11.</span>
          <span>
            Your feedback and contributions to customer surveys and
            questionnaires.
          </span>
        </p>

        <p>
          <span>17.12.</span>
          <span>
            We may also use personal information from other sources, such as
            specialist companies that supply information, online media channels,
            our partners, and public registers.{" "}
          </span>
        </p>

        <p>
          <span>17.13.</span>
          <span>
            We may be required by law to collect personal information about you
            or as a consequence of any contractual relationship we have with you
          </span>
        </p>

        <p>
          <b>
            <span>
              18.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>WHY AND HOW WE USE YOUR PERSONAL INFORMATION </span>
            </u>
          </b>
        </p>

        <p>
          <b>
            <span>18.1.</span>
          </b>
          <b>
            <span>Personalise your experience</span>
          </b>
        </p>

        <p>
          <span>
            18.1.1.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Personalise marketing messages. Where necessary we will obtain your
            consent first{" "}
          </span>
        </p>

        <p>
          <span>
            18.1.2.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Using your online browsing behaviour to help us better understand
            you as a customer{" "}
          </span>
        </p>

        <p>
          <span>
            18.1.3.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Providing you with relevant marketing communications, relating to
            products and services we offer
          </span>
        </p>

        <p>
          <span>
            18.1.4.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Providing you with relevant information, relating to products and
            services our suppliers and partners.{" "}
          </span>
        </p>

        <p>
          <b>
            <span>18.2.</span>
          </b>
          <b>
            <span>Contact and interact with you</span>
          </b>
        </p>

        <p>
          <span>
            18.2.1.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            We may use your personal information when we communicate with you,
            in order to:
          </span>
        </p>

        <p>
          <span>
            18.2.2.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>Contact you about our Services</span>
        </p>

        <p>
          <span>
            18.2.3.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>Manage promotions and competitions you take part in</span>
        </p>

        <p>
          <span>
            18.2.4.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            We may invite you to take part in market research activities{" "}
          </span>
        </p>

        <p>
          <b>
            <span>18.3.</span>
          </b>
          <b>
            <span>We analyse information</span>
          </b>
        </p>

        <p>
          <span>
            18.3.1.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Manage and improve our day-to-day operations, Websites and Mobile
            Apps
          </span>
        </p>

        <p>
          <span>
            18.3.2.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Help to develop and improve our product range, services, information
            technology systems{" "}
          </span>
        </p>

        <p>
          <span>
            18.3.3.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>Detect and prevent fraud or other crime.</span>
        </p>

        <p>
          <b>
            <span>
              19.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>SHARING PERSONAL INFORMATION </span>
            </u>
          </b>
        </p>

        <p>
          <b>
            <span>19.1.</span>
          </b>
          <b>
            <span>Retail Partners</span>
          </b>
        </p>

        <p>
          <span>
            19.1.1.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            We may share your personal information with Retail Partners that
            sell products through our Services (&quot;Retail Partners&quot;).{" "}
          </span>
        </p>

        <p>
          <span>
            19.1.2.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            We only share personal information that enables our Retail Partners
            to provide their services. For example, when you shop online or
            in-store we will give the relevant Retail Partner your name, contact
            details, and address so that they can deliver your items.
          </span>
        </p>

        <p>
          <b>
            <span>19.2.</span>
          </b>
          <b>
            <span>Service Providers</span>
          </b>
        </p>

        <p>
          <span>
            19.2.1.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            We work with Service Providers that carry out certain functions on
            our behalf. These include, for example, companies that help us with
            technology services, storing and combining data, processing payments
            and delivering orders. We only share personal information that
            enables our Service Providers to provide their services.
          </span>
        </p>

        <p>
          <span>
            19.2.2.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Some of the Service Providers we work with operate online media
            channels, and they place relevant online advertising for our
            products and services, as well as those of our suppliers and our
            Retail Partners, on those online media channels on our behalf. For
            example, you may see an advert for our products and services when
            you use a particular social media site.
          </span>
        </p>

        <p>
          <span>
            19.2.3.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Note that our Application may, from time to time, contain links to
            and from the websites of our partner networks, advertisers, and
            affiliates. If you follow a link to any of these websites, please
            note that these websites have their own privacy policies and that we
            do not accept any responsibility or liability for these policies.
            Please check these policies before you submit any personal data or
            other information to these websites.
          </span>
        </p>

        <p>
          <b>
            <span>
              20.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>
                SHARING PERSONAL INFORMATION WITH OTHER ORGANISATIONS{" "}
              </span>
            </u>
          </b>
        </p>

        <p>
          <span>20.1.</span>
          <span>
            If any applicable law or a public authority says we must share the
            personal information.
          </span>
        </p>

        <p>
          <span>20.2.</span>
          <span>
            If we need to share personal information in order to establish,
            exercise or defend our legal rights{" "}
          </span>
        </p>

        <p>
          <span>20.3.</span>
          <span>
            To an organisation we sell or transfer any of our businesses or any
            of our rights or obligations.{" "}
          </span>
        </p>

        <p>
          <span>20.4.</span>
          <span>
            If we sell/transfer our legal ownership, the organisation can use
            your personal information in the same way as us
          </span>
        </p>

        <p>
          <span>20.5.</span>
          <span>
            To anyone to whom we transfer or may transfer our rights and duties
            under our agreement with you.
          </span>
        </p>

        <p>
          <span>20.6.</span>
          <span>
            We may disclose personal data to other companies that perform
            services for our transactions{" "}
          </span>
        </p>

        <p>
          <span>20.7.</span>
          <span>
            We do not sell, share, or rent any such personal information, unless
            required by law{" "}
          </span>
        </p>

        <p>
          <span>20.8.</span>
          <span>We take every precaution to protect users' information</span>
        </p>

        <p>
          <b>
            <span>
              21.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>HOW WE PROTECT PERSONAL INFORMATION </span>
            </u>
          </b>
        </p>

        <p>
          <span>21.1.</span>
          <span>
            We maintain physical, technical, and organisational safeguards to
            secure your personal information from unauthorized access, use,
            alteration and destruction.{" "}
          </span>
        </p>

        <p>
          <span>21.2.</span>
          <span>
            Your personal information may be transferred to, stored, and
            processed in a country that is different from your country of
            residence or our country of incorporation. In any case, we have put
            in place appropriate safeguards in accordance with applicable legal
            requirements to ensure that your data is adequately protected.
          </span>
        </p>

        <p>
          <span>21.3.</span>
          <span>
            The Information that we collect from you may be transferred to, and
            stored at, a destination outside of the UAE. It may also be
            processed by staff operating outside the UAE who work for us or for
            one of our suppliers. Such staff may be engaged in, among other
            things, the fulfilment of your order, the processing of your payment
            details and the provision of support services. We will store your
            Information for as long as necessary to fulfil the purposes
            indicated in this Privacy Policy or as otherwise permitted or
            required by law.{" "}
          </span>
        </p>

        <p>
          <span>21.4.</span>
          <span>
            In the event your personal information is transferred to a foreign
            jurisdiction, it may be subject to the laws of that jurisdiction and
            we may be required to disclose it to the courts, law enforcement or
            governmental authorities in those jurisdictions but we will only do
            so where required by applicable laws.{" "}
          </span>
        </p>

        <p>
          <span>21.5.</span>
          <span>
            No internet-based site can be 100% secure and we cannot be held
            responsible for unauthorised or unintended access that is beyond our
            control.
          </span>
        </p>

        <p>
          <b>
            <span>
              22.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>SOCIAL MEDIA</span>
            </u>
          </b>
        </p>

        <p>
          <span>22.1.</span>
          <span>
            We operate channels, pages and accounts on some social media sites
            to engage with customers and improve our services.
          </span>
        </p>

        <p>
          <span>22.2.</span>
          <span>
            We are not responsible for any information posted on those sites
            other than information we have posted ourselves.{" "}
          </span>
        </p>

        <p>
          <span>22.3.</span>
          <span>
            We do not endorse the social media sites themselves or any
            information posted on them by third parties.
          </span>
        </p>

        <p>
          <span>22.4.</span>
          <span>
            The following companies (not limited to), Facebook, Twitter,
            Instagram, Youtube, Google and Linkedin, may use data for their own
            purposes, specifically to market to you through their social media
            platforms and allow engagement on our websites. This includes
            profiling and targeting you with other advertising.
          </span>
        </p>

        <p>
          <b>
            <span>
              23.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>RETENTION OF RECORDS</span>
            </u>
          </b>
        </p>

        <p>
          <span>23.1.</span>
          <span>
            To manage transactions and provide the other relevant products or
            services{" "}
          </span>
        </p>

        <p>
          <span>23.2.</span>
          <span>
            To personalise the products, services and communications with you{" "}
          </span>
        </p>

        <p>
          <span>23.3.</span>
          <span>
            To comply with the applicable record retention requirements{" "}
          </span>
        </p>

        <p>
          <span>23.4.</span>
          <span>
            To comply with our legal obligations, resolve disputes, and enforce
            our agreements.
          </span>
        </p>

        <p>
          <span>23.5.</span>
          <span>
            If you wish to request that we no longer use your information to
            provide you services, please contact us{" "}
          </span>
        </p>

        <p>
          <b>
            <span>
              24.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>MARKETING COMMUNICATIONS</span>
            </u>
          </b>
        </p>

        <p>
          <span>24.1.</span>
          <span>
            All marketing communications we send to you will provide you with a
            way to withdraw your consent to future marketing.{" "}
          </span>
        </p>

        <p>
          <span>24.2.</span>
          <span>
            If you no longer wish to receive promotional materials you may
            opt-out from receiving these communications or change your account
            settings, this will remove you from marketing lists.
          </span>
        </p>

        <p>
          <span>24.3.</span>
          <span>
            Please note that if you unsubscribe from marketing communications
            you will still receive operational and service messages from us
            regarding the services or products you bought from us and responses
            to enquiries made to us.
          </span>
        </p>

        <p>
          <b>
            <span>
              25.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>HOW WE COLLECT DATA INFORMATION </span>
            </u>
          </b>
        </p>

        <p>
          <span>25.1.</span>
          <span>
            We may use cookies and similar technologies, such as tags and pixels
            (Cookies), to personalise and improve your customer experience as
            you use our Apps and to provide you with relevant online
            advertising. Cookies are small data files that allow a website or
            Mobile App to collect and store a range of data on your desktop
            computer, laptop or mobile device.
          </span>
        </p>

        <p>
          <span>25.2.</span>
          <span>
            Cookies help us to provide important features and functionality on
            our Apps, and we use them to improve your customer experience. For
            example, we use Cookies to do the following:
          </span>
        </p>

        <p>
          <span>
            25.2.1.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Improve the way our Websites and Mobile Apps work so that we can
            personalise your experience{" "}
          </span>
        </p>

        <p>
          <span>
            25.2.2.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Improve the performance of our Websites and Mobile Apps by telling
            us if you get an error message{" "}
          </span>
        </p>

        <p>
          <span>
            25.2.3.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Deliver relevant online advertising that we believe is most relevant
            to you on our Apps{" "}
          </span>
        </p>

        <p>
          <span>
            25.2.4.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Measuring the effectiveness of our marketing communications,
            including online advertising
          </span>
        </p>

        <p>
          <span>
            25.2.5.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Deliver online advertising that is relevant to you, we may also
            combine data we collect through Cookies with other data that we have
            collected (e.g. Loyalty program).
          </span>
        </p>

        <p>
          <span>25.3.</span>
          <span>
            Cookies may collect information about your online behaviour, such as
            your IP address, the website you arrived from and information about
            your purchase history or the content of your shopping basket. This
            means that you may see our adverts on our Websites and on other
            organisations websites. You may also see adverts for other
            organisations on our Websites.
          </span>
        </p>

        <p>
          <span>25.4.</span>
          <span>Cookies Usage</span>
        </p>

        <p>
          <span>
            25.4.1.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>Your choices when it comes to Cookies</span>
        </p>

        <p>
          <span>
            25.4.1.1.
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span>
            You can use your browser settings to accept/reject new Cookies /
            delete existing Cookies
          </span>
        </p>

        <p>
          <span>
            25.4.1.2.
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span>
            You can set your browser to notify you each time new Cookies are
            placed on your device
          </span>
        </p>

        <p>
          <span>
            25.4.1.3.
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span>
            If you choose to disable some or all Cookies, you may not be able to
            make full use of our Apps
          </span>
        </p>

        <p>
          <span>
            25.4.1.4.
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span>
            Once you have given us your consent to use of cookies, we shall
            store cookies on your device.{" "}
          </span>
        </p>

        <p>
          <span>
            25.4.1.5.
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span>
            If you wish to withdraw your consent, you will need to delete our
            cookies using your browser settings.
          </span>
        </p>

        <p>
          <span>
            25.4.2.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>Keep me signed in</span>
        </p>

        <p>
          <span>
            25.4.2.1.
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span>
            This keeps you logged in where password would not be required for
            days to access the App
          </span>
        </p>

        <p>
          <span>
            25.4.2.2.
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span>
            This is to enhance smooth user experience of using our Apps
          </span>
        </p>

        <p>
          <span>
            25.4.2.3.
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span>
            This should only be selected on owner devices and never selected on
            public or shared devices
          </span>
        </p>

        <p>
          <span>
            25.4.3.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            Acquiring your personal data may occur in the following
            circumstances:
          </span>
        </p>

        <p>
          <span>
            25.4.3.1.
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span>When you register for our services on the App</span>
        </p>

        <p>
          <span>
            25.4.3.2.
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span>
            When you shop with us online, browse our websites, or use our mobile
            applications
          </span>
        </p>

        <p>
          <span>
            25.4.3.3.
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span>
            When you contact us or we contact you or you join our promotions
          </span>
        </p>

        <p>
          <span>
            25.4.4.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            While you are transacting on our App, personally identifiable
            information collected may include:
          </span>
        </p>

        <p>
          <span>
            25.4.4.1.
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span>
            First and last name, email address, contact details including phone
            numbers, and ZIP code
          </span>
        </p>

        <p>
          <span>
            25.4.4.2.
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span>
            Demographic profile (like your age, gender, occupation, education,
            address, etc)
          </span>
        </p>

        <p>
          <span>
            25.4.4.3.
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span>Personal preferences and interests</span>
        </p>

        <p>
          <span>
            25.4.4.4.
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span>
            Your opinion on services, products, and features on our App
          </span>
        </p>

        <p>
          <span>
            25.4.5.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>We may also collect the following information about:</span>
        </p>

        <p>
          <span>
            25.4.5.1.
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span>The pages you visit/access</span>
        </p>

        <p>
          <span>
            25.4.5.2.
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span>The links you click on our App</span>
        </p>

        <p>
          <span>
            25.4.5.3.
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span>The number of times you access the page</span>
        </p>

        <p>
          <span>
            25.4.5.4.
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span>Things you view, add to cart</span>
        </p>

        <p>
          <span>
            25.4.5.5.
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span>
            Other websites you open simultaneously while browsing ours
          </span>
        </p>

        <p>
          <b>
            <span>
              26.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>RIGHTS TO ACCESS YOUR DATA</span>
            </u>
          </b>
        </p>

        <p>
          <span>26.1.</span>
          <span>
            To see or access the personal information we hold about you
          </span>
        </p>

        <p>
          <span>26.2.</span>
          <span>Rectify the information we hold about you</span>
        </p>

        <p>
          <span>26.3.</span>
          <span>Erase your personal information</span>
        </p>

        <p>
          <span>26.4.</span>
          <span>Restrict our use of your personal information</span>
        </p>

        <p>
          <span>26.5.</span>
          <span>Object to our use of your personal information</span>
        </p>

        <p>
          <span>26.6.</span>
          <span>
            Withdraw any consents you have provided for our use of your personal
            information
          </span>
        </p>

        <p>
          <span>26.7.</span>
          <span>
            Receive your personal information in a usable electronic format and
            transmit it to a third party{" "}
          </span>
        </p>

        <p>
          <span>26.8.</span>
          <span>
            You have the right to objection in relation to direct marketing.{" "}
          </span>
        </p>

        <p>
          <span>26.9.</span>
          <span>
            You have the right to complain about how we have used your personal
            data.
          </span>
        </p>

        <p>
          <span>26.10.</span>
          <span>
            Lodge a complaint with your local data protection authority.
          </span>
        </p>

        <p>
          <b>
            <span>
              27.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>REVIEWS </span>
            </u>
          </b>
        </p>

        <p>
          <span>27.1.</span>
          <span>
            You agree that you are the sole author of ratings and reviews you
            submit to this App
          </span>
        </p>

        <p>
          <span>27.2.</span>
          <span>
            You are responsible and own the intellectual property rights that
            relate to the content you submitted
          </span>
        </p>

        <p>
          <span>27.3.</span>
          <span>
            You agree that you would not submit anything that is false,
            inaccurate, or misleading, whereas
          </span>
        </p>

        <p>
          <span>
            27.3.1.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            if you have been paid for such compensation that it influenced the
            content of your review;{" "}
          </span>
        </p>

        <p>
          <span>
            27.3.2.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            if content have been copied from anyone else, or infringes on any
            third partys copyright or trademark;{" "}
          </span>
        </p>

        <p>
          <span>
            27.3.3.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            if content you submitted violates any law or is considered to be
            offensive;{" "}
          </span>
        </p>

        <p>
          <span>
            27.3.4.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            if information provided includes references to other websites;
          </span>
        </p>

        <p>
          <span>
            27.3.5.
            <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <span>
            if information provided contains computer viruses, worms or other
            potentially damaging computer files
          </span>
        </p>

        <p>
          <span>27.4.</span>
          <span>
            We reserve the right to remove or to refuse to post any submission
            for any reason.
          </span>
        </p>

        <p>
          <b>
            <span>
              28.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>E-COMMUNICATIONS</span>
            </u>
          </b>
        </p>

        <p>
          <span>28.1.</span>
          <span>
            When you visit the app or send emails to the app, you are
            communicating with us electronically. You consent to receive
            communications from us electronically. We will communicate with you
            by email or by posting notices on the App.{" "}
          </span>
        </p>

        <p>
          <span>28.2.</span>
          <span>
            You agree that all agreements, notices, disclosures and other
            communications that we provide to you electronically satisfy any
            legal requirement that such communications be in writing.
          </span>
        </p>

        <p>
          <b>
            <span>
              29.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>OBJECTIONABLE MATERIALS</span>
            </u>
          </b>
        </p>

        <p>
          <span>29.1.</span>
          <span>
            You understand that by using this App or any services provided on
            the App, you may encounter content that may be deemed by some to be
            offensive, indecent, or objectionable, which content may or may not
            be identified as such
          </span>
        </p>

        <p>
          <span>29.2.</span>
          <span>
            You agree to use the App and any service at your sole risk and that
            to the fullest extent permitted under applicable law,{" "}
          </span>
        </p>

        <p>
          <span>29.3.</span>
          <span>
            We and our affiliates &amp; partners shall have no liability to you
            for content that may be deemed offensive, indecent, or objectionable
            to you.
          </span>
        </p>

        <p>
          <b>
            <span>
              30.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>COPYRIGHT/TRADEMARKS</span>
            </u>
          </b>
        </p>

        <p>
          <span>30.1.</span>
          <span>
            Unless otherwise noted, all contents of the App are copyrights,
            trademarks, trade dress and/or other intellectual property owned,
            controlled or licensed by Big Box Souq Portal LLC and are protected
            by the applicable laws.
          </span>
        </p>

        <p>
          <span>30.2.</span>
          <span>
            We and our suppliers and licensors expressly reserve all
            intellectual property rights in all text, programs, products,
            processes, technology, content and other materials, which appear on
            this App.{" "}
          </span>
        </p>

        <p>
          <span>30.3.</span>
          <span>
            All rights, including copyright, in this App are owned by or
            licensed to us.
          </span>
        </p>

        <p>
          <span>30.4.</span>
          <span>
            Any use of this Website or its contents, including copying or
            storing it or them in whole or part, other than for your own
            personal, non-commercial use is prohibited without our permission
          </span>
        </p>

        <p>
          <span>30.5.</span>
          <span>
            You may not modify, distribute or re-post anything on this App for
            any purpose.
          </span>
        </p>

        <p>
          <span>30.6.</span>
          <span>
            Our names and logos and all related product and service names,
            design marks and slogans are the trademarks or service marks of Big
            Box Souq Portal LLC.{" "}
          </span>
        </p>

        <p>
          <span>30.7.</span>
          <span>
            No trademark or service mark license is granted in connection with
            the materials contained on this App.{" "}
          </span>
        </p>

        <p>
          <span>30.8.</span>
          <span>
            Access to this App does not authorize anyone to use any name, logo
            or mark in any manner whatsoever.
          </span>
        </p>

        <p>
          <b>
            <span>
              31.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>DISCLAIMER</span>
            </u>
          </b>
        </p>

        <p>
          <span>31.1.</span>
          <span>
            We try to make sure that the delivery of purchased products shall be
            delivered on time. No claim, penalties, refunds or part refund
            against us in case of delays in delivery or error in delivery.{" "}
          </span>
        </p>

        <p>
          <span>31.2.</span>
          <span>
            Although We aim to offer you the best service possible, if a fault
            occurs in our services you should report it immediately to us and we
            will attempt to correct the fault within reasonable time.
          </span>
        </p>

        <p>
          <span>31.3.</span>
          <span>
            The product details, information, ingredients, nutritional guides
            and dietary/allergy advice may change from time to time. We highly
            recommend that you read the label on the products you purchase and
            not rely on information provided on the App.
          </span>
        </p>

        <p>
          <span>31.4.</span>
          <span>
            We do not represent or warrant that the information contained in
            this App is accurate, complete and up-to-date.{" "}
          </span>
        </p>

        <p>
          <span>31.5.</span>
          <span>
            We are not be responsible or liable for any damages of any kind
            whatsoever arising out of, caused by or related to your use of this
            App.
          </span>
        </p>

        <p>
          <b>
            <span>
              32.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>UNLAWFUL TRANSACTIONS</span>
            </u>
          </b>
        </p>

        <p>
          <span>32.1.</span>
          <span>
            We reserve the right to recover the cost of products, collection
            charges and lawyers fees from persons using the App fraudulently.{" "}
          </span>
        </p>

        <p>
          <span>32.2.</span>
          <span>
            We may suspend or close your account immediately at our reasonable
            discretion or if you breach any of your obligations under these
            Terms and Conditions. You will not be able to order products on the
            App if your account is suspended or closed.
          </span>
        </p>

        <p>
          <span>32.3.</span>
          <span>
            We reserve the right to initiate legal proceedings against such
            persons for fraudulent use of the App and any other unlawful acts or
            acts or omissions in breach of these Terms and Conditions.
          </span>
        </p>

        <p>
          <b>
            <span>
              33.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>MISCELLANEOUS</span>
            </u>
          </b>
        </p>

        <p>
          <span>33.1.</span>
          <span>
            You may not transfer any of your rights under these Terms and
            Conditions to any other person.{" "}
          </span>
        </p>

        <p>
          <span>33.2.</span>
          <span>
            We may transfer our rights under these Terms and Conditions to
            another business where we reasonably believe your rights will not be
            affected.
          </span>
        </p>

        <p>
          <span>33.3.</span>
          <span>
            If you breach these Terms and Conditions and we choose to ignore
            this, we will still be entitled to use our rights and remedies at a
            later date or in any other situation where you breach these Terms
            and Conditions.
          </span>
        </p>

        <p>
          <span>33.4.</span>
          <span>
            Neither party shall be responsible for any breach of these Terms and
            Conditions, caused by circumstances beyond that party's control.
          </span>
        </p>

        <p>
          <span>33.5.</span>
          <span>
            If you feel you have been the victim of a fraud, or suspect
            fraudulent activity carried out in the name of Big Box Souq Portal
            LLC, please contact us.
          </span>
        </p>

        <p>
          <span>33.6.</span>
          <span>
            Big Box Souq Portal LLC is not liable for any sort of loss or damage
            that occur as a result of fraudulent schemes.{" "}
          </span>
        </p>

        <p>
          <span>33.7.</span>
          <span>
            We may run promotions in our business activities. In the case that
            you win a prize in our promotion, we will never request payment of a
            fee or charge to deliver that prize.
          </span>
        </p>

        <p>
          <span>33.8.</span>
          <span>
            Scams may take a multitude of forms, in addition to those described
            below. We strongly encourage you to remain alert at all times and
            exercise caution when sharing your personal information.
          </span>
        </p>

        <p>
          <b>
            <span>
              34.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>LIMITATION OF LIABILITY </span>
            </u>
          </b>
        </p>

        <p>
          <span>34.1.</span>
          <span>
            Under no circumstance, including, but not limited to, negligence,
            shall we be liable for any indirect, special, exemplary, or
            consequential damages that result from the use of, or the inability
            to use, including but not limited to the information, materials on
            the App, page content, page code, user services or the software.{" "}
          </span>
        </p>

        <p>
          <span>34.2.</span>
          <span>
            While we shall take reasonable precautions against security
            breaches, no App or internet transmission is completely secure, and
            as such, we shall not be liable for any indirect, special,
            exemplary, or consequential damages that may result from
            unauthorized access, hacking, data loss or other breached that may
            occur.
          </span>
        </p>

        <p>
          <span>34.3.</span>
          <span>
            We or any of our affiliates, employees, directors, agents or
            suppliers shall not be liable to you or to any other person for any
            indirect, special, incidental or consequential losses or damages of
            any nature arising out of or in connection with the use of or
            inability to use the App, including without limitation damages for
            loss of profit, accuracy of results, loss of data, work stoppage, or
            computer failure.{" "}
          </span>
        </p>

        <p>
          <span>34.4.</span>
          <span>
            We make no representations or warranties about the accuracy,
            reliability, completeness, correctness and/or timeliness of any
            content, information, software, text, graphics, links or
            communications provided on or through the use of the App or that the
            operation of the App will be error free and/or uninterrupted.
            Consequently, we assume no liability whatsoever for any monetary or
            other damage suffered by you on account of the delay, failure,
            interruption, or corruption of any data or other information
            transmitted in connection with use of the App; and/or any
            interruption or errors in the operation of the App.
          </span>
        </p>

        <p>
          <span>34.5.</span>
          <span>
            Without prejudice to the generality of the section above, our total
            liability to you for all liabilities arising out of these Terms and
            Conditions is it in tort or contract is limited to the value of the
            product ordered by you.
          </span>
        </p>

        <p>
          <b>
            <span>
              35.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>INDEMNITY </span>
            </u>
          </b>
        </p>

        <p>
          <span>35.1.</span>
          <span>
            By accessing the App, the users, persons and third parties agree and
            undertake to act responsibly and cause no harm, loss or damage to
            Big Box Souq Portal LLC.{" "}
          </span>
        </p>

        <p>
          <span>35.2.</span>
          <span>
            In the event that we incurs any loss, harm or damage then we shall
            be in right to take appropriate steps to identify the person(s)
            involved and seek indemnity and compensation for all loss, claim,
            demand, damage, costs, charges (including legal fees and charges) or
            prejudice that may be caused to us on account of misuse of the App
            and the consequent affect upon us.
          </span>
        </p>

        <p>
          <span>35.3.</span>
          <span>
            You agree to defend, indemnify, and hold us harmless and its
            affiliates from and against any and all claims, damages, costs and
            expenses, including attorneys' fees, arising from or related to your
            use of the App or any breach by you of these Terms and Conditions
            and/or the applicable laws.
          </span>
        </p>

        <p>
          <b>
            <span>
              36.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>ENTIRE AGREEMENT</span>
            </u>
          </b>
        </p>

        <p>
          <span>36.1.</span>
          <span>
            If any part of these Terms and Conditions is determined to be
            invalid or unenforceable pursuant to applicable law including, but
            not limited to, the warranty disclaimers and liability limitations
            set forth above, then the invalid or unenforceable provision will be
            deemed to be superseded by a valid, enforceable provision that most
            closely matches the intent of the original provision and the
            remainder of these Terms and Conditions shall continue in effect.{" "}
          </span>
        </p>

        <p>
          <span>36.2.</span>
          <span>
            Unless otherwise specified herein, these Terms and Conditions
            constitute the entire agreement between you and us with respect to
            the App sites/services and it supersedes all prior or
            contemporaneous communications and proposals, whether electronic,
            oral or written, between you and us with respect to the App
            sites/services.{" "}
          </span>
        </p>

        <p>
          <span>36.3.</span>
          <span>
            Our failure to act with respect to a breach by you or others does
            not waive its right to act with respect to subsequent or similar
            breaches.
          </span>
        </p>

        <p>
          <span>36.4.</span>
          <span>
            Because we sell products for personal use only, our responsibility
            to you shall not include any business losses, such as lost data,
            lost profits, lost sales or business interruption.
          </span>
        </p>

        <p>
          <b>
            <span>
              37.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>TERMINATION</span>
            </u>
          </b>
        </p>

        <p>
          <span>37.1.</span>
          <span>
            These Terms and Conditions is effective unless and until terminated
            by either you or us.{" "}
          </span>
        </p>

        <p>
          <span>37.2.</span>
          <span>
            You may terminate these Terms and Conditions at any time, provided
            that you discontinue any further use of this App.{" "}
          </span>
        </p>

        <p>
          <span>37.3.</span>
          <span>
            We may terminate these Terms and Conditions at any time and may do
            so immediately without notice, and accordingly deny your access to
            the App. Such termination will be without any liability to us.{" "}
          </span>
        </p>

        <p>
          <span>37.4.</span>
          <span>
            Upon any termination of these Terms and Conditions by either you or
            us you must promptly destroy all materials downloaded or otherwise
            obtained from this App, as well as all copies of such materials,
            whether made under these Terms and Conditions or otherwise.{" "}
          </span>
        </p>

        <p>
          <span>37.5.</span>
          <span>
            Our right to any feedback shall survive any termination of these
            Terms and Conditions. Any such termination of these Terms and
            Conditions shall not cancel your obligation to pay for the product
            already ordered from the App or affect any liability that may have
            arisen under these Terms and Conditions.
          </span>
        </p>

        <p>
          <b>
            <span>
              38.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>GOVERNING LAW AND JURISDICTION </span>
            </u>
          </b>
        </p>

        <p>
          <span>38.1.</span>
          <span>
            The Terms and Conditions shall be construed in accordance with the
            federal laws of the United Arab Emirates and the local laws and
            regulations of the Emirate of Dubai.{" "}
          </span>
        </p>

        <p>
          <span>38.2.</span>
          <span>
            The Courts at Dubai shall have exclusive jurisdiction in any
            proceedings arising out of the Terms and Conditions.
          </span>
        </p>

        <p>
          <b>
            <span>
              39.
              <span>&nbsp;&nbsp;</span>
            </span>
          </b>
          <b>
            <u>
              <span>AMENDMENT OF THE TERMS &amp; CONDITIONS</span>
            </u>
          </b>
        </p>

        <p>
          <span>39.1.</span>
          <span>
            We may at any time modify the Terms and Conditions of the App
            without any prior notification to you.{" "}
          </span>
        </p>

        <p>
          <span>39.2.</span>
          <span>
            You can access the latest version of the Terms and Conditions at any
            given time on the App.{" "}
          </span>
        </p>

        <p>
          <span>39.3.</span>
          <span>Please review this Policy periodically for changes. </span>
        </p>

        <p>
          <span>39.4.</span>
          <span>
            In the event the modified Terms and Conditions are not acceptable to
            you, you should discontinue using the service of the App. However,
            if you continue to use the service you shall be deemed to have
            agreed to accept and abide by the modified Terms &amp; Conditions of
            this App.
          </span>
        </p>

        <p>
          <span>
            40.
            <span>&nbsp;&nbsp;</span>
          </span>
          <b>
            <u>
              <span>CORPORATE &amp; CONTACT DETAILS</span>
            </u>
          </b>
        </p>

        <p>
          <span>40.1.</span>
          <span>
            Big Box Souq Portal LLC is a Limited Liability Company, incorporated
            under the federal laws of the United Arab Emirates and the local
            laws of the Emirate of Dubai
          </span>
        </p>

        <p>
          <span>40.2.</span>
          <span>
            Big Box Souq Portal LLC, PO Box No: 232352, Dubai, United Arab
            Emirates
          </span>
        </p>

        <p>
          <span>40.3.</span>
          <span>hi@bigboxsouq.com</span>
        </p>

        <p>
          <span>40.4.</span>
          <span>Tel: +9714 2800907</span>
        </p>

        <p>
          <i>
            <span>
              Last updated: 15<sup>th</sup> May 2021
            </span>
          </i>
        </p>
      </div>
    </div>
  );
}

export default TermAndCondPage;
