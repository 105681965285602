import android from "../assets/qr-code-GPlay_APP_BBS.png";
import ios from "../assets/qr-code-iOS_APP_BBS.png";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";
import { handleCloseModal } from "../store/slices";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Box className="modal-header-custom">{children}</Box>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function QRDownload() {
  const handleClose = () => {
    dispatch(handleCloseModal("connect"))
  };
  const connect = useSelector(state => state.modal?.connect)
  const dispatch = useDispatch()
  return (
    <div>
      <Dialog
        // fullWidth
        scroll="body"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={connect}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <h4 style={{ textAlign: "center" }}>Download Our App</h4>
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <a href="https://play.google.com/store/apps/details?id=com.app.bigboxsouq" rel="noopener noreferrer" target="_blank">
                <img
                  src={android}
                  alt="Instagram QR Code"
                  style={{ width: "100%" }}
                />
              </a>
              <p style={{fontSize: 12, textAlign: "center"}}>Scan me to Download Android App</p>
            </div>
            <div>
              <a href="https://apps.apple.com/ae/app/big-box-souq/id1568651637" rel="noopener noreferrer" target="_blank">
                <img
                  src={ios}
                  alt="Instagram QR Code"
                  style={{ width: "100%" }}
                />
              </a>
              <p style={{fontSize: 12, textAlign: "center"}}>Scan me to Download IOS App</p>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
