import React from "react";
import aboutusImg from "../assets/aboutus.png";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { handleCloseModal } from "../store/slices";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Box className="modal-header-custom">{children}</Box>
      {onClose ? (
        <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function Aboutus() {
  const aboutus = useSelector((state) => state.modal?.aboutus);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(handleCloseModal("aboutus"));
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={aboutus}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <img src={aboutusImg} alt="aboutus" className="ModalImage" />
          <h1 className="about-title">About BigBoxSouq</h1>
        </DialogTitle>
        <DialogContent>
          <blockquote className="blockquote-aboutus">
            <p className="">
              <span className="quoteMark">&ldquo;</span>Big Box Souq was founded
              by Zainab Malubhai in 2021, a mother of two, living in Dubai.
              Zainab began to recognize the frustrations of ordering groceries
              online, particularly with food wastage due to large minimum
              quantities, finding environmentally conscious businesses, and a
              great variety of fresh fruit and vegetables that were readily
              available.
            </p>
          </blockquote>
          <blockquote className="blockquote-aboutus">
            <p className="">
              <span className="quoteMark">&ldquo;</span>I recognized that it was incredibly difficult to find a grocery
            delivery company that would let you order the exact quantity that
            you needed, at a reasonable price, with an environmentally conscious
            mindset. As a mother of two, I wanted to be able to order food for
            my family that was fresh, with no food wastage, and that understood
            the impact food wastage and repackaging had on our environment.
            Therefore, I wanted to create a new grocery concept that had
            healthy, mindful eating at the core of it’s values - Zainab Malubhai
            </p>
          </blockquote>
          {/* <p className="aboutus-info">
          </p> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}
