import aboutusImg from "../assets/aboutus.jpg";

export default function AboutusPage() {
  return (
    <div className="main container">
      <header className="aboutusHeader row">
        <div className="col-lg-7 col-md-12">
          <h1 className="about-title">
            About<div>Big Box Souq</div>
          </h1>
          <div className="about-box">
            <div className="aboutus-info">
              Big Box Souq was founded by
              Zainab Malubhai in 2021, a mother of two, living in Dubai. Zainab
              began to recognize the frustrations of ordering groceries online,
              particularly with food wastage due to large minimum quantities,
              finding environmentally conscious businesses, and a great variety of
              fresh fruit and vegetables that were readily available.
            </div>
            <blockquote className="blockquote-aboutus">
              <div className="">
              I recognized that it was
                incredibly difficult to find a grocery delivery company that would let
                you order the exact quantity that you needed, at a reasonable price,
                with an environmentally conscious mindset. As a mother of two, I
                wanted to be able to order food for my family that was fresh, with no
                food wastage, and that understood the impact food wastage and
                repackaging had on our environment. Therefore, I wanted to create a
                new grocery concept that had healthy, mindful eating at the core of
                it’s values 
                <div>Zainab Malubhai</div>
              </div>
            </blockquote>
          </div>
        </div>
        <div className="align-items-baseline col-lg-5 col-md-12 d-flex justify-content-end">
          <img src={aboutusImg} alt="aboutus" className="aboutusimage" />
        </div>
      </header>
    </div>
  );
}
