import gql from "graphql-tag";

export const SingupCustomer = gql`
  mutation singupCustomer(
    $username: String!
    $email: String!
    $mobile: String!
    $OTP: Int!
    $referral_code: String
    $nationality: String
    $age: String
    $gender: String
  ) {
    singupCustomer(
      username: $username
      email: $email
      mobile: $mobile
      referral_code: $referral_code
      nationality: $nationality
      age: $age
      gender: $gender
      OTP: $OTP
    ) {
      id
      firstname
      lastname
      email
      referral_code
      age
      nationality
      mobile
      message
    }
  }
`;
export const Contactus = gql`
  mutation contactus(
    $name: String!
    $email: String!
    $mobile: String!
    $message: String!
  ) {
    contactus(name: $name, email: $email, mobile: $mobile, message: $message) {
      success
    }
  }
`;
export const GenerateOTP = gql`
  mutation generateOTP($source: String!, $source_type: Int!, $gpc: String) {
    generateOTP(source: $source, source_type: $source_type, gpc: $gpc) {
      id
    }
  }
`;
export const VerifyOTP = gql`
  mutation verifyOTP($source: String!, $source_type: Int!, $OTP: Int!) {
    verifyOTP(source: $source, source_type: $source_type, OTP: $OTP) {
      id
    }
  }
`;
export const ListCustomers = gql`
  query {
    listCustomers {
      id
      mobile
      firstname
      lastname
      email
      referral_code
    }
  }
`;
export const listCountries = gql`
query {
  listCountries {
    id
    name
    iso_code_3
    iso_code_2
    zones {
      name
      id
    }
  }
}
`;

