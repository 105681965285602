import { useState } from "react";
import success from "../../assets/success.png";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import android from "../../assets/qr-code-GPlay_APP_BBS.png";
import ios from "../../assets/qr-code-iOS_APP_BBS.png";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Box className="modal-header-custom">{children}</Box>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
function Success({
  setShowModal,
  setRegister,
  customerName,
  custumerRefCode,
  errorMessage,
  showModal,
  setErrorMessage
}) {
  const [copy, setCopy] = useState(false);

  const copyLink = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/signup/${custumerRefCode}`
    );
    setCopy(true);
  };
  const handleClose = () => {
      setShowModal(false);
      setRegister(false);
      setErrorMessage("")
  };
  return (
    <Dialog
      fullWidth
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={showModal}
    >
      {!errorMessage ? (
        <>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {/* <img src={connect} alt="Instagram QR Code" className="ModalImage" /> */}
            <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <a href="https://play.google.com/store/apps/details?id=com.app.bigboxsouq" rel="noopener noreferrer" target="_blank">
                <img
                  src={android}
                  alt="Instagram QR Code"
                  style={{ width: "100%" }}
                />
              </a>
              <p style={{fontSize: 12, textAlign: "center"}}>Scan me to Download Android App</p>
            </div>
            <div>
              <a href="https://apps.apple.com/ae/app/big-box-souq/id1568651637" rel="noopener noreferrer" target="_blank">
                <img
                  src={ios}
                  alt="Instagram QR Code"
                  style={{ width: "100%" }}
                />
              </a>
              <p style={{fontSize: 12, textAlign: "center"}}>Scan me to Download IOS App</p>
            </div>
          </div>
            <h5 className="modal-title">Congratulations {customerName}</h5>
          </DialogTitle>
          <DialogContent
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <span className="modal-title">
              Your account has been created successfully.
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <input
                type="text"
                value={`${window.location.origin}/signup/${custumerRefCode}`}
                onChange={() => {}}
                readOnly
                className="linkInput"
              />
              <span style={{ width: "1%" }}></span>
              <button
                className="btn"
                onClick={() => copyLink()}
                style={{ background: "#00ab83", color: "white" }}
              >
                {!copy ? "Copy" : "Copied"}
              </button>
            </div>
            <br />
            <button className="btn modal-btn" onClick={handleClose}>
              OK
            </button>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {errorMessage === "You are already registered" ? (
              <img src={success} alt="img" className="ModalImage" />
            ) : null}
          </DialogTitle>
          <DialogContent
            className="modal-body"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {errorMessage === "You are already registered" ? (
              <>
                <span className="modal-title mb-2">
                  {`Hi! ${customerName}, This number is already registered with us. See link below.`}
                </span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <input
                    type="text"
                    value={`${window.location.origin}/signup/${custumerRefCode}`}
                    onChange={() => {}}
                    readOnly
                    className="linkInput"
                  />
                  <span style={{ width: "1%" }}></span>
                  <button
                    className="btn"
                    onClick={() => copyLink()}
                    style={{ background: "#00ab83", color: "white" }}
                  >
                    {!copy ? "Copy" : "Copied"}
                  </button>
                </div>
                <br />
                <button className="btn modal-btn" onClick={handleClose}>
                  OK
                </button>
              </>
            ) : (
              <span className="modal-title">{errorMessage}</span>
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}

export default Success;
