import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { TextField } from "@material-ui/core";
import ModalImage from "../assets/contactus.png";
import { Contactus } from "./queries";
import { Captcha } from 'primereact/captcha';
import { useDispatch, useSelector } from "react-redux";
import { handleCloseModal } from "../store/slices";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  modal: {
    width: "600px"
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Box className="modal-header-custom">{children}</Box>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function Aboutus() {
  const [contactus] = useMutation(Contactus);
  const [code, setCode] = useState("+971");
  const [successful, setSuccessful] = useState(false);
  const [capcha, setCapcha] = useState(false);
  const [error, setError] = useState();
  const handleClose = () => {
    dispatch(handleCloseModal("contactus"))
    setCapcha(false);
    setError("");
  };
  const contact = useSelector(state => state.modal?.contactus)
  const dispatch = useDispatch()
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={contact}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <img src={ModalImage} alt="ModalImage" className="ModalImage" />
          {!successful ? (
            <>
              <h4 className="conatact-title">Contact us</h4>
              {error ? <p style={{ color: "red" }}>{error}</p> : null}
            </>
          ) : null}
        </DialogTitle>
        {!successful ? (
          <DialogContent>
            <Formik
              initialValues={{
                name: "",
                email: "",
                mobile: "",
                message: "",
              }}
              validateOnChange={true}
              validateOnBlur={false}
              onSubmit={async (values) => {
                try {
                  if (capcha) {
                    await contactus({
                      variables: {
                        name: values.name,
                        email: values.email,
                        mobile: `${code}${values.mobile}`,
                        message: values.message,
                      },
                    });
                    setSuccessful(true);
                    setCode("+971");
                    setError("");
                    setCapcha(false);
                  }else{
                    setError("Pleace complete captcha below")  
                  }
                } catch (error) {
                  setError(error.message);
                  setCapcha(false);
                }
              }}
              validationSchema={Yup.object({
                name: Yup.string().required("Name is required field"),
                email: Yup.string().email().required("Email is required field"),
                mobile: Yup.string().required("Mobile No. is required field"),
                message: Yup.string().required("Message is required field"),
              })}
            >
              {(formik) => {
                return (
                  <Form className="modelForm" onSubmit={formik.handleSubmit}>
                    <div className="form-group">
                      <Field
                        as={TextField}
                        label="Name *"
                        fullWidth
                        name="name"
                        type="text"
                      />
                      <ErrorMessage
                        component="div"
                        name="name"
                        className="error"
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        as={TextField}
                        label="Email *"
                        fullWidth
                        name="email"
                        type="email"
                      />
                      <ErrorMessage
                        component="div"
                        name="email"
                        className="error"
                      />
                    </div>
                    <div className="form-group">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <input
                          className="code"
                          value={`${code}`}
                          onChange={(e) => setCode(e.target.value)}
                          readOnly
                          type="text"
                        />
                        <Field
                          as={TextField}
                          fullWidth
                          label="Mobile *"
                          id="mobile"
                          name="mobile"
                          type="tel"
                        />
                      </div>
                      <ErrorMessage
                        component="div"
                        name="mobile"
                        className="error"
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        as={TextField}
                        label="Message *"
                        fullWidth
                        multiline
                        rows={4}
                        rowsMax={4}
                        name="message"
                        type="text"
                      />
                      <ErrorMessage
                        component="div"
                        name="message"
                        className="error"
                      />
                    </div>
                    <div
                      className="form-group">
                      <Captcha siteKey={process.env.REACT_APP_GOOGLE_SITE_KEY} onResponse={() => setCapcha(true)} />
                    </div>
                    <div
                      className="form-group"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn"
                        disabled={
                          formik.isSubmitting ||
                          !(formik.isValid &&
                          formik.dirty) ||
                          !capcha
                        }
                      >
                        {!formik.isSubmitting ? (
                          <span>Submit</span>
                        ) : (
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Submit{" "}
                            <span
                              className="spinner-border text-light"
                              style={{ marginLeft: "10px" }}
                            ></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </DialogContent>
        ) : (
          <DialogContent style={{ textAlign: "center" }}>
            <h5 className="conatact-title">Thank you for contacting us!</h5>
            <p>We will reply to your message as soon as possible</p>
            <button
              className="btn modal-btn"
              onClick={() => {
                setSuccessful(false);
                dispatch(handleCloseModal("contactus"))
                setError("");
              }}
            >
              Close
            </button>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
}
