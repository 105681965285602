import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import TermAndCond from "./Component/TermAndCondPage";
import Home from "./Component/Home";
import NotFount from './Component/NotFound';
import Layout from "./Component/Layout";
import AboutusPage from "./Component/AboutusPage";
import PartnershipPage from "./Component/PartnershipPage";

function Routes() {
  return (
    <BrowserRouter>
    <Layout>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/aboutus" exact component={AboutusPage} />
        <Route path="/partnership" exact component={PartnershipPage} />
        <Route path="/termandcondition" exact component={TermAndCond} />
        <Route path="/404" exact component={NotFount}/>
        <Route path="/signup/:ref_code" component={Home} />
        <Redirect from="/signup" to="/signup/HM5ZM3" />
        <Redirect from="/:ref_code" to="/signup/:ref_code" />
      </Switch>
    </Layout>
    </BrowserRouter>
  );
}

export default Routes;
