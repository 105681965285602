
import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import one from "../assets/one.png"; 
// import two from "../assets/two.png"; 
// import three from "../assets/three.png"; 
import one from "../assets/1.png"; 
import two from "../assets/2.png"; 
import three from "../assets/3.png"; 


// function DotsCustom(props) {
//   const { className, style } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style,   fontSize: '16px', }}
//     />
//   );
// }
export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      // dotsClass:  <DotsCustom />,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 4000,
      autoplaySpeed: 3500,
    //   cssEase: "linear",
      pauseOnHover: true,
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
            <img src={one} alt="Slider 1" className="sliderImagesDem"  /> 
             {/* height={650} */}
          </div>
          <div>
          <img src={two} alt="Slider 2" className="sliderImagesDem"  />
          </div>
          <div>
          <img src={three} alt="Slider 3" className="sliderImagesDem"  />
          </div>
        </Slider>
      </div>
    );
  }
}
