import { createSlice } from "@reduxjs/toolkit";

export const modalReducer = createSlice({
  name: "modal",
  initialState: {
    aboutus: false,
    contactus: false,
    register: false,
    connect: false,
    termCondi: false,
    socailLinks: false,
  },
  reducers: {
    handleCloseModal: (state, type) => {
      switch (type.payload) {
        case "aboutus":
          state.aboutus = false;
          break;
        case "contactus":
          state.contactus = false;
          break;
        case "register":
          state.register = false;
          break;
        case "connect":
          state.connect = false;
          break;
        case "termCondi":
          state.termCondi = false;
          break;
        case "socialLinks":
        state.socailLinks = false;
        break;
        default:
          break;
      }
    },
    handleOpenModal: (state, type) => {
      switch (type.payload) {
        case "aboutus":
          state.aboutus = true;
          break;
        case "contactus":
          state.contactus = true;
          break;
        case "register":
          state.register = true;
          break;
        case "connect":
          state.connect = true;
          break;
        case "termCondi":
          state.termCondi = true;
          break;
        case "socialLinks":
          state.socailLinks = true;
          break;
        default:
          break;
      }
    },
  },
});

export const { handleCloseModal, handleOpenModal } = modalReducer.actions;

export default modalReducer.reducer;
