import "./style.css"
import image1 from "../../assets/feature1.png"
import image2 from "../../assets/feature2.png"
import image3 from "../../assets/feature3.png"

function FeautureCard() {
    return (
        <div className="feature-section-main text-center container">
            <div className="row">
                <div className="col-lg-4 col-md-12">
                    <div className="feature-card-main ">
                        <div className="feature-card-head">
                            <img src={image1} alt="feature"/>
                            <div>
                                <h2>BIG SAVINGS</h2>
                            </div>
                        </div>
                        <div className="feature-card-body">
                        <dl>
                            <dt>REFER TO SAVE</dt>
                            <dd>YOU earn points when your referral shops with us!</dd>
                            <dt>POINTS TO WALLET</dt>
                            <dd>Points can be redeemed to shop with us.</dd>
                            <dt>GREAT VALUE</dt>
                            <dd>Great prices, and YOU can even shop per piece</dd>
                        </dl>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12">
                    <div className="feature-card-main">
                        <div className="feature-card-head">
                            <img src={image2} alt="feature"/>
                            <div>
                                <h2>BIG INITIATIVES</h2>
                            </div>
                        </div>
                        <div className="feature-card-body">
                        <dl>
                            <dt>100% RECYCLED</dt>
                            <dd>Reusable and recyclable packaging</dd>
                            <dt>NO FOOD WASTAGE</dt>
                            <dd>Order only what YOU need!</dd>
                            <dt>BUSINESS SUPPORT </dt>
                            <dd>Partnerships with Local Businesses</dd>
                            <dt>THE STORY GROUP</dt>
                            <dd>1 Billion Tree initiative partnership</dd>
                        </dl>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12">
                    <div className="feature-card-main">
                        <div className="feature-card-head">
                            <img src={image3} alt="feature" />
                            <div>
                                <h2>BIG FEATURES</h2>
                            </div>
                        </div>
                        <div className="feature-card-body">
                        <dl>
                            <dt>SMART CART</dt>
                            <dd>Our App can predict items, quantities & provide recommendations</dd>
                            <dt>INTUITIVE APP</dt>
                            <dd>Search in 12 languages with our straight forward app</dd>
                            <dt>SIMPLE & EASY </dt>
                            <dd>Easy to use with a simple, no-fuss shopping experience </dd>
                        </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeautureCard
