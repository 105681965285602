import FeautureCard from "./Feature/FeautureCard";
import { useDispatch } from 'react-redux';
import { handleOpenModal } from '../store/slices';
import  SimpleSlider  from "./Galleria";

function Home() {
  const dispatch = useDispatch()

  return (
      <div className="main">
        <div className="row home-main">
          <div className="col-md-12 feature-section px-lg-5">
              <SimpleSlider />
          </div>
          <div className="col-md-12 feature-section">
             &nbsp;
             <br/>
             &nbsp;
          </div>
          <div className="col-12 download-btn">
            <button onClick={() => dispatch(handleOpenModal("connect"))}>Download App</button>           
          </div>
          <div className="col-md-12 feature-section pb-5">
              <FeautureCard />
          </div>
          <br/>
          <br/>
          <div className="container px-lg-5">
            <div className="col-md-12 px-lg-5">
            <div className="wistia_responsive_padding" style={{padding:"56.25% 0 0 0", position:"relative"}}>
                <div className="wistia_responsive_wrapper" style={{height:"100%",left:0,position:"absolute",top:0,width:"100%",}}>
                  <span className="wistia_embed wistia_async_d7v1es7q0i popover=true popoverAnimateThumbnail=true videoFoam=true" 
                    style={{display:"inline-block",height:"100%",position:"relative",width:"100%"}}>&nbsp;</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Home;
