import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";
import { handleCloseModal } from "../store/slices";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Box className="modal-header-custom">{children}</Box>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function SocialLinksModal() {
  const handleClose = () => {
    dispatch(handleCloseModal("socialLinks"))
  };
  const socailLink = useSelector(state => state.modal?.socailLinks)
  const dispatch = useDispatch()
  return (
    <div>
      <Dialog
        // fullWidth
        scroll="body"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={socailLink}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <h4 style={{ textAlign: "center" }}>Follow us</h4>
        </DialogTitle>
        <DialogContent>
          <div className="modal-nav-social-link">
              <a href="https://www.facebook.com/bigboxsouqdubai/?show_switched_toast=0&show_switched_tooltip=0&show_podcast_settings=0" rel="noreferrer" target="_blank"><i className="fab fa-facebook"></i></a>
              <span style={{width: "20px"}}></span>
              <a href="https://api.whatsapp.com/send?phone=+971585534118" rel="noreferrer" target="_blank"><i className="fab fa-whatsapp"></i></a>
              <span style={{width: "20px"}}></span>
              <a href="https://www.instagram.com/bigboxsouq/" rel="noreferrer" target="_blank"><i className="fab fa-instagram"></i></a>
              <span style={{width: "20px"}}></span>
              <a href="mailto:hi@bigboxsouq.com" rel="noreferrer" target="_blank"><i className="fas fa-envelope"></i></a>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
