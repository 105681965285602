import { useLocation } from "react-router";
import TermAndCondPage from "./TermAndCondPage";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux"
import {handleCloseModal} from "../store/slices"

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Box className="modal-header-custom">{children}</Box>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function TermAndCondModal() {
  const termCondi = useSelector(state => state.modal?.termCondi)
  const location = useLocation();
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(handleCloseModal("termCondi"))
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  if (location.pathname === "/termandcondition") {
    return null;
  }
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={termCondi}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></DialogTitle>
        <DialogContent>
          <TermAndCondPage />
        </DialogContent>
      </Dialog>
    </div>
  );
}
